<template>
  <div class="room-box">
    <div class="room-box-imagearea">
      <div class="room-box-image"><img :src="roomData.photo_url" /></div>
    </div>
    <div class="room-box-infoarea">
      <p class="room-box-name">{{ roomData.name }}</p>
      <ul class="room-box-info">
        <li>
          <icon-door></icon-door>
          <p>{{ roomData.kind_label }}（{{ roomData.capacity_label }}）</p>
          <p v-if="roomData.kind_sex_label" class="room-box-info-label">
            {{ roomData.kind_sex_label }}
          </p>
        </li>
        <li>
          <icon-bed></icon-bed>
          <p>{{ roomData.bed_labels.join(', ') }}</p>
        </li>
        <li v-if="roomData.desk_chair_info">
          <icon-desk></icon-desk>
          <p>{{ roomData.desk_chair_info }}</p>
        </li>
        <li v-if="!isLegacyPlan">
          <icon-ticket></icon-ticket>
          <p>1泊{{ roomData.ticket_amount }}枚</p>
        </li>
      </ul>
      <div v-if="roomData.notice" class="text-xs mt-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 25 25"
          fill="none"
          stroke="#950008"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="12" y1="8" x2="12" y2="12"></line>
          <line x1="12" y1="16" x2="12.01" y2="16"></line>
        </svg>
        {{ roomData.notice }}
      </div>
    </div>
  </div>
</template>

<script>
import IconBed from '../../shared/icons/bed.vue'
import IconDoor from '../../shared/icons/door.vue'
import IconDesk from '../../shared/icons/desk.vue'
import IconTicket from '../../shared/icons/ticket.vue'

export default {
  components: { IconBed, IconDoor, IconDesk, IconTicket },
  props: {
    roomData: {
      type: Object,
      required: true,
    },
    isLegacyPlan: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
