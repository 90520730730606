function addSpinner(event) {
  event.preventDefault()

  const submitButton = document.querySelector('#plan_change-submit')
  const backButton = document.querySelector('#plan_change-back')
  submitButton.disabled = true
  backButton.style.display = 'none'

  const spinner = document.createElement('div')
  spinner.classList.add('inline-spinner')
  spinner.style.display = 'inline-block'
  submitButton.appendChild(spinner)

  event.target.submit()
}

document.addEventListener('DOMContentLoaded', () => {
  const form = document.querySelector('#plan_change-form')
  if (form != null) form.addEventListener('submit', addSpinner)
})
