<template>
  <button class="favorite-btn" @click="toggleFavorite">
    <div v-if="isFavorited">
      <span class="c_card-favorit active">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#fff"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"
          ></path>
        </svg>
      </span>
    </div>
    <div v-else>
      <span class="c_card-favorit">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#fff"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"
          ></path>
        </svg>
      </span>
    </div>
    <span v-if="shouldShowText" class="text-gray7 text-xs pl-1">
      {{ isFavorited ? unfavoriteText : favoriteText }}
    </span>
  </button>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    initialIsFavorited: {
      type: Boolean,
      default: false,
    },
    apiUrl: {
      type: String,
      required: true,
    },
    errorRedirectUrl: {
      type: String,
      required: true,
    },
    favoriteText: {
      type: String,
      default: null,
    },
    unfavoriteText: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isFavorited: this.initialIsFavorited,
    }
  },
  computed: {
    shouldShowText() {
      return this.favoriteText || this.unfavoriteText
    },
  },
  async mounted() {
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }
  },
  methods: {
    async toggleFavorite() {
      if (this.isFavorited) {
        await axios.delete(this.apiUrl).then(() => {
          this.isFavorited = false
        })
      } else {
        await axios
          .post(this.apiUrl)
          .then(() => {
            this.isFavorited = true
          })
          .catch((e) => {
            if (e.response.status === 401) {
              location.href = this.errorRedirectUrl
            }
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.favorite-btn {
  display: flex;
  align-items: center;
}
</style>
