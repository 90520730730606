<template>
  <div>
    <div v-for="(select_box, index) in select_boxes" :key="select_box.id" class="companion-select">
      <label class="form-label">同伴者名</label>
      <button type="button" class="btn-text btn-upperright" @click="removeSelectBox(index)">削除</button>
      <select :id="index" class="form-select" @change="selected">
        <option>選択する</option>
        <option
          v-for="user in accompanyUsers"
          :key="user.id"
          :value="user.id"
          :selected="user.id === select_box.accompany_user_id"
        >
          {{ user.last_name }} {{ user.first_name }}（{{ user.age_kind }}）
        </option>
      </select>
    </div>
    <input type="hidden" name="reservation[accompany_user_ids]" :value="selected_users.join(',')" />
    <button v-if="canAddSelectBox" type="button" class="btn btn-white btn-single" @click="addSelectBox()">同伴者を追加</button>
  </div>
</template>

<script>
export default {
  props: {
    maxUsers: {
      type: Number,
      default: 10,
    },
    accompanyUsers: {
      type: Array,
      default: () => [],
    },
    default: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      select_boxes: [],
      selected_users: [],
    }
  },
  computed: {
    canAddSelectBox() {
      return this.select_boxes.length < this.maxUsers
    },
  },
  mounted() {
    this.default.forEach((id) => {
      this.select_boxes.push({
        id: this.$uuid.v4(),
        accompany_user_id: id,
      })
      this.updateSelectedUsers()
    })
  },
  methods: {
    addSelectBox() {
      if (this.canAddSelectBox) {
        this.select_boxes.push({
          id: this.$uuid.v4(),
          accompany_user_id: null,
        })
      }
    },
    removeSelectBox(index) {
      this.select_boxes.splice(index, 1)
      this.updateSelectedUsers()
    },
    selected(e) {
      const select_box_index = e.target.id
      const selected_user_id = e.target.value
      if (selected_user_id === '選択する') {
        this.select_boxes[select_box_index]['accompany_user_id'] = null
      } else {
        this.select_boxes[select_box_index]['accompany_user_id'] = parseInt(selected_user_id)
      }
      this.updateSelectedUsers()
    },
    updateSelectedUsers() {
      this.selected_users = this.select_boxes.filter((box) => !!box['accompany_user_id']).map((box) => box['accompany_user_id'])
    },
  },
}
</script>
