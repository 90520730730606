const $ = require('jquery')
require('slick-carousel')

const windowWidth = $(window).width()
const windowSm = 768

$(function () {
  if (windowWidth <= windowSm) {
    const elements = document.getElementsByClassName('home-related-content-inner')
    Array.from(elements).map((ele) => ele.classList.remove('carousel-related-content'))
  }
})

$(function () {
  $('.carousel-related-content').slick({
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
  })
  $('.scroller-pc-3').slick({
    infinite: false,
    variableWidth: true,
    slidesToScroll: 3,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: 'unslick',
      },
    ],
  })
  $('.scroller-pc-2').slick({
    infinite: false,
    variableWidth: true,
    slidesToScroll: 1,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 767,
        settings: 'unslick',
      },
    ],
  })
  $('.scroller-pc-1').slick({
    infinite: false,
    variableWidth: true,
    slidesToScroll: 1,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 767,
        settings: 'unslick',
      },
    ],
  })
  $('.top-themelist-sub-carousel').slick({
    infinite: false,
    variableWidth: true,
    slidesToScroll: 1,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 767,
        settings: 'unslick',
      },
    ],
  })
  $('.homedetail-relatedcontent-carousel').slick({
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 767,
        settings: 'unslick',
      },
    ],
  })
  //要素が3つ以下の場合はslick解除
  const slideLength = document.querySelectorAll('.homedetail-relatedstores-card').length
  if (slideLength <= 3) {
    $('.homedetail-relatedcontent-carousel').addClass('unslick')
  }
  $('.unslick.slick-initialized').slick('unslick')
})
