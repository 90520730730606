// Javascript Setup
import 'javascripts/web/application'
// Turn on if you need it
require('@rails/ujs').start()
// import "core-js/stable";

// Specific Script
import './homes/index'
import './homes/slick'
import './mypage/inline_spinner'

import clipboard from 'clipboard'

import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'
// CSS Setup
import 'stylesheets/web/application'

// Images Setup(asset_pack_pathなどのヘルパーに利用されるので削除不可能)
// eslint-disable-next-line no-unused-vars
const images = require.context('../../images', true)
// eslint-disable-next-line no-unused-vars
const imagePath = (name) => images(name, true)
const importAll = (r) => r.keys().map(r)
importAll(require.context('images', false, /\.(png|jpe?g|svg)$/i))

// Vue Setup
import Vue from 'vue/dist/vue.esm.js'
import VModal from 'vue-js-modal'
import VueDragscroll from 'vue-dragscroll'
import VueLazyLoad from 'vue-lazyload'
import UUID from 'vue-uuid'

import YamoriReservationCalendar from '../../vuejs/shared/components/YamoriReservationCalendar'
import ReservationAccompanyUserForm from '../../vuejs/web/components/ReservationAccompanyUserForm'
import ReservationTempAccompanyUserForm from '../../vuejs/web/components/ReservationTempAccompanyUserForm'
import RoomSearch from '../../vuejs/web/components/RoomSearch'
import RoomSearchModal from '../../vuejs/web/components/RoomSearchModal'
import RoomReserveCalendar from '../../vuejs/web/components/RoomReserveCalendar'
import HomeCalendar from '../../vuejs/web/components/homes/HomeCalendar.vue'
import HeaderDropDown from '../../vuejs/shared/components/HeaderDropDown'
import HomeImageGallery from '../../vuejs/web/components/HomeImageGallery'
import RoomSelect from '../../vuejs/web/components/RoomSelect'
import ThemeListEditor from '../../vuejs/web/components/ThemeListEditor.vue'
import FavoriteButton from '../../vuejs/web/components/FavoriteButton.vue'
import ReportModal from '../../vuejs/web/components/ReportModal.vue'
import ContentMenu from '../../vuejs/web/components/ContentMenu.vue'
import ReportButton from '../../vuejs/web/components/ReportButton.vue'
import ImageCarousel from '../../vuejs/shared/components/ImageCarousel.vue'
import YamoriModal from '../../vuejs/web/components/YamoriModal.vue'
import InfoModal from '../../vuejs/web/components/InfoModal.vue'
import HomeReviews from '../../vuejs/web/components/HomeReviews.vue'
import HomeShareModal from '../../vuejs/web/components/HomeShareModal.vue'
import ProfileShareModal from '../../vuejs/web/components/ProfileShareModal.vue'
import HomeFavoriteButton from '../../vuejs/web/components/HomeFavoriteButton.vue'
import WordCounter from '../../vuejs/web/components/WordCounter.vue'
import SearchBoxWide from '../../vuejs/web/components/SearchBoxWide.vue'

Vue.use(UUID)
Vue.use(VModal)
Vue.use(VueDragscroll)
Vue.use(VueLazyLoad)

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('yamoriReservationCalendar') !== null) {
    new Vue({
      el: '#yamoriReservationCalendar',
      render: (h) => h(YamoriReservationCalendar, { props: $('#yamoriReservationCalendar').data() }),
    })
  }

  if (document.getElementById('reservationAccompanyUserForm') !== null) {
    new Vue({
      el: '#reservationAccompanyUserForm',
      render: (h) => h(ReservationAccompanyUserForm, { props: $('#reservationAccompanyUserForm').data() }),
    })
  }

  if (document.getElementById('reservationTempAccompanyUserForm') !== null) {
    new Vue({
      el: '#reservationTempAccompanyUserForm',
      render: (h) => h(ReservationTempAccompanyUserForm, { props: $('#reservationTempAccompanyUserForm').data() }),
    })
  }

  if (document.getElementById('roomSearch') !== null) {
    new Vue({
      el: '#roomSearch',
      name: 'RoomSearch',
      render: (h) => h(RoomSearch, { props: $('#roomSearch').data() }),
    })
  }

  if (document.getElementById('roomSearchModal') !== null) {
    new Vue({
      el: '#roomSearchModal',
      name: 'RoomSearchModal',
      render: (h) => h(RoomSearchModal, { props: $('#roomSearchModal').data() }),
    })
  }

  if (document.getElementById('room-calendar') !== null) {
    const calendarModalVue = new Vue({
      el: '#room-calendar',
      name: 'RoomCalendar',
      components: { RoomReserveCalendar, HomeCalendar },
      data() {
        return {
          selectedCheckInDate: null,
          selectedCheckOutDate: null,
        }
      },
      methods: {
        checkInChanged(date) {
          this.selectedCheckInDate = date
        },
        checkOutChanged(date) {
          this.selectedCheckOutDate = date
        },
        openModalByDate(roomId, checkInDate, checkOutDate) {
          this.selectedCheckInDate = checkInDate
          this.selectedCheckOutDate = checkOutDate
          $(`[data-modal-name="room-${roomId}"]`).modal('show')
          this.$refs[`room-calender-${roomId}`].modalShown(true)
        },
      },
    })
    document.querySelectorAll('.calendar-modal').forEach((modal, index) => {
      modal.addEventListener('show.bs.modal', () => {
        // このVueコンポーネントは最初にHomeCalendar、以降にRoomReserveCalendarが並ぶ
        calendarModalVue.$children[index + 1].modalShown(false)
      })
    })
  }

  if (document.getElementById('room-select') !== null) {
    new Vue({
      el: '#room-select',
      name: 'RoomSelect',
      components: { RoomSelect },
    })
  }

  if (document.getElementById('headerDropDown') !== null) {
    new Vue({
      el: '#headerDropDown',
      name: 'HeaderDropDown',
      render: (h) => h(HeaderDropDown, { props: $('#headerDropDown').data() }),
    })
  }

  if (document.getElementById('home-image-gallery') !== null) {
    new Vue({
      el: '#home-image-gallery',
      name: 'HomeImageGallery',
      components: { HomeImageGallery },
    })
  }

  if (document.getElementById('theme-list-editor') !== null) {
    new Vue({
      el: '#theme-list-editor',
      components: { ThemeListEditor },
    })
  }

  if (document.getElementById('favorite-button') !== null) {
    new Vue({
      el: '#favorite-button',
      name: 'FavoriteButton',
      components: { FavoriteButton },
    })
  }

  if (document.getElementById('has-contentmenu') !== null) {
    new Vue({
      el: '#has-contentmenu',
      name: 'ContentMenu',
      components: { ContentMenu, ReportButton },
    })
  }

  if (document.querySelector('.copy-btn') !== null) {
    document.querySelectorAll('.copy-btn').forEach((element) => {
      element.addEventListener('click', (e) => {
        e.preventDefault()
        clipboard.copy(element.dataset.copyValue)
        element.parentElement.querySelector('.d-none')?.classList?.remove('d-none')
      })
    })
  }

  if (document.querySelector('.tickets-detail-button') !== null) {
    document.querySelectorAll('.tickets-detail-button').forEach((element) => {
      element.addEventListener('click', (e) => {
        e.preventDefault()

        var wrapper = document.querySelector('.tickets-detail-table-wrap')
        if (!wrapper) return
        if (wrapper.style.display === 'flex') {
          wrapper.style.display = 'none'
          document.querySelector('.tickets-detail-icon').classList?.remove('is-open')
        } else {
          wrapper.style.display = 'flex'
          document.querySelector('.tickets-detail-icon').classList?.add('is-open')
        }
      })
    })
  }

  if (document.getElementById('globalContainer') !== null) {
    window.GlobalVueInstance = new Vue({
      el: '#globalContainer',
      name: 'GlobalContainer',
      components: {
        ReportModal,
      },
      methods: {
        showReportModalWithInfo(info) {
          this.$refs.globalReportModal.openWithInfo(info)
        },
      },
    })
  }

  if (document.getElementById('image-carousel') !== null) {
    new Vue({
      el: '#image-carousel',
      components: { ImageCarousel },
    })
  }

  if (document.getElementById('has-home-list') !== null) {
    new Vue({
      el: '#has-home-list',
      components: { YamoriModal },
    })
  }

  if (document.getElementById('home-about-modal') !== null) {
    new Vue({
      el: '#home-about-modal',
      name: 'HomeAbountModal',
      components: { InfoModal },
    })
  }

  if (document.getElementById('home-amenity-modal') !== null) {
    new Vue({
      el: '#home-amenity-modal',
      name: 'HomeAmenityModal',
      components: { InfoModal },
    })
  }

  if (document.getElementById('area') !== null) {
    new Vue({
      el: '#area',
      name: 'AreaInfo',
      components: { InfoModal },
    })
  }

  if (document.getElementById('yamori') !== null) {
    new Vue({
      el: '#yamori',
      name: 'YamoriInfo',
      components: { InfoModal },
    })
  }

  if (document.getElementById('reviews') !== null) {
    new Vue({
      el: '#reviews',
      name: 'ReviewInfo',
      components: { HomeReviews },
    })
  }

  if (document.getElementById('home-share-modal') !== null) {
    new Vue({
      el: '#home-share-modal',
      name: 'HomeShareModal',
      components: { HomeShareModal },
    })
  }

  if (document.getElementById('profile-share-modal') !== null) {
    new Vue({
      el: '#profile-share-modal',
      name: 'ProfileShareModal',
      components: { ProfileShareModal },
    })
  }

  if (document.getElementById('home-favorite-button') !== null) {
    new Vue({
      el: '#home-favorite-button',
      name: 'HomeFavoriteButton',
      components: { HomeFavoriteButton },
    })
  }

  if (document.getElementById('has-word-counter') !== null) {
    new Vue({
      el: '#has-word-counter',
      name: 'WordCounter',
      components: { WordCounter },
    })
  }

  if (document.getElementById('search-box-wide') !== null) {
    new Vue({
      el: '#search-box-wide',
      components: { SearchBoxWide },
    })
  }
})
