<template>
  <div>
    <div v-for="(user, index) in users" :key="user.id" class="companion-input">
      <button type="button" class="btn-text btn-upperright" @click="removeUser(index)">削除</button>
      <div class="form">
        <div class="mb-3">
          <label :for="createId(index, 'name')" class="form-label">氏名</label>
          <div class="flex">
            <input
              :id="createId(index, 'last_name')"
              v-model="user.last_name"
              type="text"
              class="form-control mr-4"
              :name="createName('last_name')"
              placeholder="姓"
            />
            <input
              :id="createId(index, 'first_name')"
              v-model="user.first_name"
              type="text"
              class="form-control"
              :name="createName('first_name')"
              placeholder="名"
            />
          </div>
        </div>
      </div>
      <div class="form">
        <div class="mb-3">
          <label :for="createId(index, 'name_kana')" class="form-label">フリガナ</label>
          <div class="flex">
            <input
              :id="createId(index, 'last_name_kana')"
              v-model="user.last_name_kana"
              type="text"
              class="form-control mr-4"
              :name="createName('last_name_kana')"
              placeholder="セイ"
            />
            <input
              :id="createId(index, 'first_name_kana')"
              v-model="user.first_name_kana"
              type="text"
              class="form-control"
              :name="createName('first_name_kana')"
              placeholder="メイ"
            />
          </div>
        </div>
      </div>
      <div class="form">
        <div class="mb-3">
          <label :for="createId(index, 'sex')" class="form-label">性別</label>
          <select :id="createId(index, 'sex')" v-model="user.sex" :name="createName('sex')" class="form-select">
            <option>選択する</option>
            <option value="0">男性</option>
            <option value="1">女性</option>
          </select>
        </div>
      </div>
      <div class="form">
        <div class="mb-3">
          <label :for="createId(index, 'birthdate')" class="form-label">生年月日</label>
          <input
            :id="createId(index, 'birthdate')"
            v-model="user.birthdate"
            type="date"
            class="form-control"
            :name="createName('birthdate')"
          />
        </div>
      </div>
    </div>
    <button v-if="canAddUser" type="button" class="btn btn-white btn-single" @click="addUser()">同伴者を追加</button>
  </div>
</template>

<script>
export default {
  props: {
    maxUsers: {
      type: Number,
      default: 10,
    },
    defaultUsers: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      users: [],
    }
  },
  computed: {
    canAddUser() {
      return this.users.length < this.maxUsers
    },
  },
  mounted() {
    this.users = this.defaultUsers
  },
  methods: {
    addUser() {
      if (this.canAddUser) {
        this.users.push({
          id: this.$uuid.v4(),
          lastName: '',
          firstName: '',
          lastNameKana: '',
          firstNameKana: '',
          sex: null,
          birthdate: null,
        })
      }
    },
    removeUser(index) {
      this.users.splice(index, 1)
    },
    createId(index, attrName) {
      return `reservation_temporary_accompany_users_${index}_${attrName}`
    },
    createName(attrName) {
      return `reservation[temporary_accompany_users][][${attrName}]`
    },
  },
}
</script>
