<template>
  <div>
    <!--
    <div class="tab">
      <a @click="getData(homeId, tabs[0].view)" :class="activeClass[tabs[0].view]">カレンダー表示</a>
      <a @click="getData(homeId, tabs[1].view)" :class="activeClass[tabs[1].view]">リスト表示[未実装]</a>
    </div>
    -->

    <div class="l-yamori-inner">
      <div class="yamori-calendar">
        <div class="yamori-calendar-action">
          <div class="year-month">{{ currentWeek }}</div>
          <div class="back" @click="prevDay()"></div>
          <div class="back-more" @click="prevDay(7)"></div>
          <div class="prev" @click="nextDay()"></div>
          <div class="prev-more" @click="nextDay(7)"></div>
        </div>
        <div class="yamori-calendar-date">
          <div v-for="c in calendar" :key="c.date" class="date-wrap">
            <div>{{ c.dd }}</div>
            <div :class="{ date: true, today: c.date == today }">{{ c.day }}</div>
          </div>
        </div>

        <div class="yamori-calendar-room">
          <div v-for="r in rooms" :key="r.id" class="yamori-calendar-room-name">
            <div>{{ r.name }}</div>
          </div>
        </div>

        <div v-if="!!showParking" class="yamori-calendar-room" style="grid-row: 4; grid-column: 1">
          <div class="yamori-calendar-room-name" style="grid-row: 1">- {{ checkOut }}</div>
          <div class="yamori-calendar-room-name" style="grid-row: 2">{{ checkOut }} - {{ checkInStarts }}</div>
          <div class="yamori-calendar-room-name" style="grid-row: 3">{{ checkInStarts }} -</div>
        </div>

        <div class="yamori-calendar-stay">
          <CalendarGrid
            v-for="r in reservations"
            :id="r.id"
            :key="setGridKey(r.id)"
            :room-id="r.room_id"
            :class-name="setClass(r.status)"
            :user-name="r.user_name"
            :check-in-date="r.check_in_date"
            :check-out-date="r.check_out_date"
            :block-reason="r.block_reason"
            :calendar="calendar"
            :rooms="rooms"
            :reservation-path="reservationPath"
          ></CalendarGrid>
        </div>

        <div v-if="!!showParking && parkingStocks.length != 0" class="yamori-calendar-parking">
          <CalendarParkingGrid v-for="(s, i) in stockGridData()" :key="s.id" :count="s.stock" :index="i"></CalendarParkingGrid>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import timezone from 'dayjs/plugin/timezone'
import 'dayjs/locale/ja'
dayjs.extend(isBetween)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')
dayjs.locale('ja')

import CalendarGrid from './CalendarGrid.vue'
import CalendarParkingGrid from './CalendarParkingGrid.vue'

export default {
  components: {
    CalendarGrid,
    CalendarParkingGrid,
  },
  // props: [
  //   'homeId',
  //   'rooms',
  //   'src',
  //   'tabs',
  //   'checkInStarts',
  //   'checkOut',
  //   'showParking',
  //   'reservationPath',
  // ],
  props: {
    homeId: {
      type: Number,
      required: true,
    },
    rooms: {
      type: Array,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
    checkInStarts: {
      type: String,
      required: true,
    },
    checkOut: {
      type: String,
      required: true,
    },
    showParking: {
      type: Boolean,
      required: true,
    },
    reservationPath: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      reservations: [],
      // activeTab: '',
      // activeClass: [],
      currentDate: dayjs().format('YYYY-MM-DD'),
      today: dayjs().format('YYYY-MM-DD'),
      currentWeek: '',
      calendar: [],
      parkingStocks: [],
    }
  },
  mounted: function () {
    this.makeCalendar()
    this.getData(this.homeId, this.currentDate)
    // this.activeTab = this.tabs[0].view
  },
  methods: {
    getData(homeId, date, callback) {
      let from_date = dayjs(date).subtract(1, 'day').format('YYYY-MM-DD')
      let to_date = dayjs(date).add(7, 'day').format('YYYY-MM-DD')
      axios
        .get(this.src, {
          params: {
            home_id: homeId,
            from_date,
            to_date,
            with_block: true,
            with_parking_stocks: this.showParking,
            show_all: true,
          },
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
          },
          responseType: 'json',
        })
        .then((response) => {
          this.reservations = response.data.results
          this.parkingStocks = response.data.parking_stocks
          typeof callback === 'function' && callback()
          // this.activeTab = view
          // this.activeClass = []
          // this.activeClass[view] = 'is-active'
        })
    },
    setGridKey(id) {
      return dayjs(this.currentDate).unix() + id
    },
    makeCalendar() {
      const startDayjs = dayjs(this.currentDate)
      const endDayjs = startDayjs.add(6, 'day')

      const currentYear = startDayjs.year()
      const currentMonth = startDayjs.month() + 1
      const endYear = endDayjs.year()
      const endMonth = endDayjs.month() + 1

      let currentWeek = `${currentYear}年${currentMonth}月`
      if (currentYear != endYear) {
        currentWeek += ` 〜 ${endYear}年${endMonth}月`
      } else if (currentMonth != endMonth) {
        currentWeek += ` 〜 ${endMonth}月`
      }
      this.currentWeek = currentWeek

      this.calendar = [...Array(7).keys()].map((i) => {
        return {
          date: startDayjs.add(i, 'day').format('YYYY-MM-DD'),
          dd: startDayjs.add(i, 'day').format('dd'),
          day: startDayjs.add(i, 'day').format('DD'),
        }
      })
    },
    setClass(status) {
      let result = ''
      switch (status) {
        case 'approved':
          result = 'stay'
          break
        case 'requested':
          result = 'requested'
          break
        case 'blocked':
          result = 'stop'
          break
      }
      return result
    },
    prevDay(subtractDay = 1) {
      const date = dayjs(this.currentDate).subtract(subtractDay, 'day').format('YYYY-MM-DD')
      this.getData(this.homeId, date, () => {
        this.currentDate = date
        this.makeCalendar()
      })
    },
    nextDay(addDay = 1) {
      const date = dayjs(this.currentDate).add(addDay, 'day').format('YYYY-MM-DD')
      this.getData(this.homeId, date, () => {
        this.currentDate = date
        this.makeCalendar()
      })
    },
    stockGridData() {
      if (this.parkingStocks.length == 0) {
        return []
      }

      return this.parkingStocks
        .filter((parking) => {
          return dayjs(parking.date).isBetween(dayjs(this.currentDate).subtract(1, 'day'), dayjs(this.currentDate).add(7, 'day'))
        })
        .map((parking) => {
          return parking.stocks
        })
        .flat()
        .map((stock) => {
          return {
            id: this.$uuid.v4(),
            stock,
          }
        })
    },
  },
}
</script>
