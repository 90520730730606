<template>
  <div>
    <div>
      <div class="themelist-edit">
        <div v-if="!isEditingCover">
          <div v-if="!savedData.cover_image_url">
            <label>
              <div class="mt-5 mb-3">
                <div class="btn themelist-edit-btn">＋ カバー画像を追加する</div>
              </div>
              <input type="file" accept="image/*" @change="onFileChange" />
            </label>
          </div>
          <div v-else>
            <div class="themelist-edit-mv position-relative">
              <img :src="savedData.cover_image_url" />
              <button class="themelist-edit-mv-delete" @click.prevent="editCover">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#ffffff"
                    stroke-width="1.8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polygon points="16 3 21 8 8 21 3 21 3 16 16 3"></polygon>
                  </svg>
                </span>
              </button>
            </div>
          </div>
          <p class="text-xs text-gray7 mt-2">
            画像の縦横比は1:1.91（横1200px 縦630px）推奨です。（参考：
            <a
              href="https://address-membersupport.zendesk.com/hc/ja/articles/22669672752793#h_01HA85410K0XECZF9G55440VQ5"
              class="text-primary underline"
              target="_blank"
            >
              画像を探すコツ
            </a>
            ）
          </p>
        </div>
        <div v-else>
          <div v-if="uploadFile">
            <div class="themelist-edit-mv position-relative">
              <img :src="uploadFileUrl" />
            </div>
            <label>
              <div class="mt-2">
                <div class="btn themelist-edit-btn">＋ カバー画像を変更する</div>
              </div>
              <input type="file" accept="image/*" @change="onFileChange" />
            </label>
            <p class="text-xs text-gray7 mt-2">
              画像の縦横比は1:1.91（横1200px 縦630px）推奨です。（参考：
              <a
                href="https://address-membersupport.zendesk.com/hc/ja/articles/22669672752793#h_01HA85410K0XECZF9G55440VQ5"
                class="text-primary underline"
                target="_blank"
              >
                画像を探すコツ
              </a>
              ）
            </p>
          </div>
          <div v-else>
            <div class="themelist-edit-mv position-relative">
              <img :src="savedData.cover_image_url" />
              <button class="themelist-edit-mv-delete" @click.prevent="removeCover">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#ffffff"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </span>
              </button>
            </div>
            <label>
              <div class="mt-1">
                <div class="btn themelist-edit-btn">＋ カバー画像を変更する</div>
              </div>
              <input type="file" accept="image/*" @change="onFileChange" />
            </label>
            <p class="text-xs text-gray7 mt-2">
              画像の縦横比は1:1.91（横1200px 縦630px）推奨です。（参考：
              <a
                href="https://address-membersupport.zendesk.com/hc/ja/articles/22669672752793#h_01HA85410K0XECZF9G55440VQ5"
                class="text-primary underline"
                target="_blank"
              >
                画像を探すコツ
              </a>
              ）
            </p>
          </div>
          <div class="themelist-edit-mv-menu mt-2">
            <div class="themelist-edit-mv-menu-buttons">
              <button class="text-gray7 mr-3" @click.prevent="cancelCover">キャンセル</button>
              <button class="btn btn-white" @click.prevent="saveCover">保存</button>
            </div>
            <p v-if="uploadErrorMessage" class="text-red text-sub mt-1">{{ uploadErrorMessage }}</p>
          </div>
        </div>

        <div class="mt-5">
          <h1 v-if="!formData.title.isEditing" class="themelist-edit-h1">
            {{ savedData.title }}
            <button class="themelist-edit-editbtn" @click.prevent="startEditing('title')">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#969696"
                stroke-width="1.8"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polygon points="16 3 21 8 8 21 3 21 3 16 16 3"></polygon>
              </svg>
            </button>
          </h1>
          <div v-else>
            <div class="themelist-edit-h1-editform">
              <input v-model="formData.title.content" type="text" class="form-control" @keydown.esc="cancelEditing('title')" />
              <button class="btn btn-white ml-3" @click.prevent="saveEditing('title')">保存</button>
            </div>
            <p v-if="formData.title.errorMessage" class="text-red text-sub mt-1">{{ formData.title.errorMessage }}</p>
          </div>
        </div>

        <div class="mt-2 mb-5">
          <div v-if="formData.description.isEditing" class="themelist-edit-description-editform">
            <textarea
              v-model="formData.description.content"
              type="text"
              class="form-control"
              @keydown.esc="cancelEditing('description')"
            ></textarea>
            <div class="ml-3">
              <button class="btn btn-white" @click.prevent="saveEditing('description')">保存</button>
              <button class="text-sub text-gray7 d-block mx-auto mt-3" @click.prevent="removeDescription">削除</button>
            </div>
          </div>
          <p v-else-if="savedData.description" class="mt-3">
            <span style="word-wrap: break-word; white-space: pre-wrap" v-text="savedData.description"></span>
            <button class="themelist-edit-editbtn" @click.prevent="startEditing('description')">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#969696"
                stroke-width="1.8"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polygon points="16 3 21 8 8 21 3 21 3 16 16 3"></polygon>
              </svg>
            </button>
          </p>
          <button v-else class="btn themelist-edit-btn" @click.prevent="startEditing('description')">
            ＋ テーマリストの説明文を設定する
          </button>
        </div>

        <component-editor
          v-for="(listComponent, index) in listComponents"
          :key="listComponent.id"
          :default-data="listComponent"
          :position="index"
          :edit-disabled="isComponentEditing"
          :edit-api-url="editApiUrl"
          @remove-component="removeListComponent"
          @enter-editing="enterEditing"
          @exit-editing="exitEditing"
          @refresh-data="fetchThemeList"
        />

        <div class="mt-4">
          <button
            v-if="isAddableComponent"
            class="themelist-edit-block-addbtn"
            :class="{ 'themelist-edit-disabled': isComponentEditing }"
            @click.prevent="addListComponent"
          >
            ＋ ブロックを追加する
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ComponentEditor from './theme_lists/ComponentEditor.vue'

export default {
  components: { ComponentEditor },
  props: {
    editApiUrl: {
      type: String,
      required: true,
    },
    defaultData: {
      type: Object,
      required: true,
    },
    dummyImageUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      maxComponentCount: 30,
      savedData: this.defaultData,
      formData: {
        title: {
          content: this.defaultData.title,
          isEditing: false,
          errorMessage: null,
        },
        description: {
          content: this.defaultData.description,
          isEditing: false,
          errorMessage: null,
        },
      },
      uploadFile: null,
      uploadErrorMessage: null,
      isEditingCover: false,
      listComponents: [],
      isComponentEditing: false,
    }
  },
  computed: {
    isAddableComponent() {
      return this.listComponents.length < this.maxComponentCount
    },
    uploadFileUrl() {
      return this.uploadFile ? URL.createObjectURL(this.uploadFile) : ''
    },
  },
  async mounted() {
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }
    await this.fetchThemeList()
    if (this.listComponents.length < 1) {
      this.addListComponent()
    }
  },
  methods: {
    async fetchThemeList() {
      await axios.get(this.editApiUrl).then((response) => {
        this.listComponents = [...response.data.theme_list.components]
      })
    },
    startEditing(key) {
      this.$set(this.formData, key, { content: this.formData[key].content, isEditing: true, errorMessage: null })
    },
    cancelEditing(key) {
      this.$set(this.formData, key, { content: this.savedData[key], isEditing: false, errorMessage: null })
    },
    hasChanges(key) {
      return this.formData[key].content !== this.savedData[key]
    },
    async saveEditing(key) {
      if (!this.hasChanges(key)) {
        this.$set(this.formData, key, { content: this.formData[key].content, isEditing: false })
        return
      }

      await axios
        .patch(this.editApiUrl, {
          theme_list: { [key]: this.formData[key].content },
        })
        .then(() => {
          this.$set(this.formData, key, { content: this.formData[key].content, isEditing: false })
          this.$set(this.savedData, key, this.formData[key].content)
        })
        .catch((error) => {
          this.$set(this.formData, key, {
            content: this.formData[key].content,
            isEditing: true,
            errorMessage: error.response.data.message,
          })
        })
    },
    async removeDescription() {
      this.$set(this.formData, 'description', { content: '' })
      await this.saveEditing('description')
    },
    onFileChange(e) {
      this.isEditingCover = true
      this.uploadFile = e.target.files[0]
    },
    editCover() {
      this.isEditingCover = true
      this.uploadErrorMessage = null
      this.uploadFile = null
    },
    cancelCover() {
      this.isEditingCover = false
      this.uploadErrorMessage = null
      this.uploadFile = null
    },
    async saveCover() {
      const formData = new FormData()
      formData.append('theme_list[cover_image]', this.uploadFile)

      await axios
        .patch(this.editApiUrl, formData)
        .then((response) => {
          this.$set(this.savedData, 'cover_image_url', response.data.theme_list.cover_image_url)
          this.uploadFile = null
          this.uploadErrorMessage = null
          this.isEditingCover = false
        })
        .catch((error) => {
          this.uploadErrorMessage = error.response.data.message
        })
    },
    async removeCover() {
      await axios
        .patch(this.editApiUrl, {
          theme_list: { cover_image: null },
        })
        .then(() => {
          this.$set(this.savedData, 'cover_image_url', null)
          this.uploadErrorMessage = null
          this.uploadFile = null
          this.isEditingCover = false
        })
        .catch((error) => {
          this.uploadErrorMessage = error.response.data.message
        })
    },
    defaultComponentData() {
      return {
        id: this.$uuid.v4(),
        is_saved: false,
      }
    },
    addListComponent() {
      this.listComponents.push(this.defaultComponentData())
    },
    async removeListComponent(id) {
      if (confirm('本当に削除しますか？')) {
        const removedListComponent = this.listComponents.find((i) => i.id === id)
        if (removedListComponent.is_saved) {
          await axios.delete(`${this.editApiUrl}/theme_list_components/${id}`).then(() => {
            this.fetchThemeList()
          })
        } else {
          this.listComponents.splice(
            this.listComponents.findIndex((c) => c.id === id),
            1
          )
        }
      }
    },
    enterEditing() {
      this.isComponentEditing = true
    },
    exitEditing() {
      this.isComponentEditing = false
    },
  },
}
</script>

<style lang="scss" scoped>
input[type='file'] {
  display: none;
}
</style>
