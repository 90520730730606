<template>
  <div class="wordcounter-group">
    <slot></slot>
    <span :class="{ 'counter-alert': count > maxCount }" class="wordcounter">{{ count }} / {{ maxCount }}</span>
  </div>
</template>

<script>
export default {
  props: {
    maxCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      count: 0,
    }
  },
  mounted() {
    const textarea = this.$el.querySelector('textarea')
    if (textarea) {
      textarea.addEventListener('input', this.updateCount)
      this.updateCount({ target: textarea })
    }
  },
  methods: {
    updateCount(event) {
      this.count = event.target.value.length
    },
  },
}
</script>
