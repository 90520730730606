<template>
  <div>
    <div class="mb-4">
      <label>日程</label>
      <p v-if="minDays > 1" class="text-gray2 pb-1">※最低予約日数{{ minDays }}日</p>
      <DateCalendarPicker
        ref="dateCalendarPicker"
        position="right"
        :start-date-value="startDateValue()"
        :end-date-value="endDateValue()"
        :start-date="calStartDate"
        :end-date="calEndDate"
        :disabled-dates="reservedDates"
        :holydays="holydays"
        :min-nights="minDays"
        @check-in-changed="checkInChanged"
        @check-out-changed="checkOutChanged"
      ></DateCalendarPicker>
    </div>

    <div class="mb-4">
      <div class="d-flex search-people-rooms">
        <div class="d-flex search-peoplebox">
          <div class="w-100">
            <label>大人</label>
            <select v-model="formData.adult" class="form-select search-people-adult">
              <option v-for="count in adultSelect" :key="`adult-${count}`" :value="count">
                {{ count }}
              </option>
            </select>
          </div>
          <div class="w-100">
            <label>子ども</label>
            <select v-model="formData.child" class="form-select search-people-child">
              <option v-for="count in rangeNumbers(0, 6)" :key="`child-${count}`" :value="count">
                {{ count }}
              </option>
            </select>
          </div>
          <div class="w-100">
            <label>幼児</label>
            <select v-model="formData.preschooler" class="form-select search-people-preschooler">
              <option v-for="count in rangeNumbers(0, 6)" :key="`preschooler-${count}`" :value="count">
                {{ count }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="!disableRoomCount" class="search-roomsbox">
          <label>部屋数</label>
          <select v-model="formData.roomCount" class="form-select">
            <option v-for="count in rangeNumbers(1, 2)" :key="`room-count-${count}`" :value="count">
              {{ count }}
            </option>
          </select>
        </div>
      </div>
      <div class="mt-2">
        <svg
          style="margin-top: 3px"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 28 28"
          fill="none"
          stroke="#CF761D"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
          <line x1="12" y1="17" x2="12.01" y2="17"></line>
        </svg>
        <a :href="capacityHelpURL" class="btn btn-text2 text-sub" target="_blank" rel="noopener">個室の定員について</a>
      </div>
    </div>

    <div class="row mb-4">
      <div class="mb-1">部屋タイプ</div>
      <div class="d-flex flex-wrap">
        <div class="checkbox mr-2">
          <input
            :id="id + '_room_kind_private'"
            v-model="formData.roomKinds"
            type="checkbox"
            :name="id + '_room_kinds'"
            value="private"
          />
          <label :for="id + '_room_kind_private'" class="ml-2">個室</label>
        </div>
        <div class="checkbox mr-2">
          <input
            :id="id + '_room_kind_dormitory'"
            v-model="formData.roomKinds"
            type="checkbox"
            :name="id + '_room_kinds'"
            value="dormitory"
          />
          <label :for="id + '_room_kind_dormitory'" class="ml-2">ドミトリー</label>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="mb-1">こだわり</div>
      <div class="checkbox">
        <input :id="id + '_desk_chair'" v-model="formData.deskChair" type="checkbox" value="1" />
        <label :for="id + '_desk_chair'" class="ml-2">部屋にデスクチェアがある</label>
      </div>
    </div>
  </div>
</template>

<script>
import DateCalendarPicker from 'vuejs/shared/components/DateCalendarPicker'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import timezone from 'dayjs/plugin/timezone'
import 'dayjs/locale/ja'
dayjs.extend(isBetween)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')
dayjs.locale('ja')

export default {
  components: { DateCalendarPicker },
  props: {
    defaultFormData: {
      type: Object,
      required: true,
    },
    calStartDate: {
      type: String,
      required: true,
    },
    calEndDate: {
      type: String,
      required: true,
    },
    reservedDates: {
      type: Array,
      default: () => [],
    },
    holydays: {
      type: Array,
      required: true,
    },
    minDays: {
      type: Number,
      required: true,
    },
    disableRoomCount: {
      type: Boolean,
      default: false,
    },
    isContracted: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      id: null,
      formData: {
        checkInDate: null,
        checkOutDate: null,
        adult: 1,
        child: 0,
        preschooler: 0,
        roomKinds: [],
        deskChair: false,
        roomCount: 1,
      },
    }
  },
  computed: {
    capacityHelpURL() {
      return this.isContracted
        ? 'https://address-membersupport.zendesk.com/hc/ja/articles/20051109977369'
        : 'https://address.zendesk.com/hc/ja/articles/23734687136153'
    },
    adultSelect() {
      return this.formData.roomCount > 1 ? this.rangeNumbers(2, 6) : this.rangeNumbers(1, 6)
    },
  },
  watch: {
    formData: {
      handler(newData) {
        if (newData.roomCount > 1) {
          this.formData.adult = Math.max(2, this.formData.adult)
        }
        this.$emit('change-form')
      },
      deep: true,
    },
  },
  created: function () {
    this.id = this._uid
    if (this.isValidDates(this.defaultFormData.check_in_date, this.defaultFormData.check_out_date)) {
      this.formData.checkInDate = dayjs(this.defaultFormData.check_in_date)
      this.formData.checkOutDate = dayjs(this.defaultFormData.check_out_date)
    }
    this.formData.adult = this.defaultFormData.adult ? this.defaultFormData.adult : 1
    this.formData.child = this.defaultFormData.child ? this.defaultFormData.child : 0
    this.formData.preschooler = this.defaultFormData.preschooler ? this.defaultFormData.preschooler : 0
    this.formData.roomCount = this.defaultFormData.room_count ? this.defaultFormData.room_count : 1
    this.formData.deskChair = !!this.defaultFormData.has_desk_chair
    if (!!this.defaultFormData.private_kind || !!this.defaultFormData.dormitory_kind) {
      let kinds = []
      if (this.defaultFormData.private_kind) {
        kinds.push('private')
      }
      if (this.defaultFormData.dormitory_kind) {
        kinds.push('dormitory')
      }
      this.formData.roomKinds = kinds
    }
  },
  mounted: function () {
    if (!this.isValidDates(this.defaultFormData.check_in_date, this.defaultFormData.check_out_date)) {
      this.$refs.dateCalendarPicker.clearSelection()
    }
  },
  methods: {
    rangeNumbers(min, max) {
      return Array(max - min + 1)
        .fill()
        .map((x, i) => i + min)
    },
    startDateValue() {
      if (this.formData.checkInDate !== null) {
        return this.formData.checkInDate.format('YYYY/MM/DD')
      }
      return null
    },
    endDateValue() {
      if (this.formData.checkOutDate !== null) {
        return this.formData.checkOutDate.format('YYYY/MM/DD')
      }
      return null
    },
    checkInChanged(date) {
      this.formData.checkInDate = date
    },
    checkOutChanged(date) {
      this.formData.checkOutDate = date
    },
    getSearchUrl() {
      const url = new URL(window.location)
      let searchParams = {}

      if (!!this.formData.checkInDate && !!this.formData.checkOutDate) {
        searchParams.check_in_date = this.formData.checkInDate.format('YYYY-MM-DD')
        searchParams.check_out_date = this.formData.checkOutDate.format('YYYY-MM-DD')
      }

      searchParams.adult = this.formData.adult
      searchParams.child = this.formData.child
      searchParams.preschooler = this.formData.preschooler
      searchParams.room_count = this.formData.roomCount
      searchParams.room_kinds = !!this.formData.roomKinds && this.formData.roomKinds.length > 0 ? this.formData.roomKinds : []
      searchParams.has_desk_chair = this.formData.deskChair

      url.search = ''
      const arrayParams = ['room_kinds']
      for (const [key, val] of Object.entries(searchParams)) {
        if (val) {
          if (arrayParams.includes(key)) {
            val
              .toString()
              .split(',')
              .forEach((v) => {
                url.searchParams.append(`q[${key}][]`, `${v}`)
              })
          } else {
            url.searchParams.append(`q[${key}]`, val.toString())
          }
        }
      }
      return url
    },
    isValidDates(startDate, endDate) {
      endDate = dayjs(endDate).subtract(1, 'day').format('YYYY-MM-DD')
      if (!this.isValidDate(startDate) || !this.isValidDate(endDate)) {
        return false
      }
      let startDay = dayjs(startDate)
      let endDay = dayjs(endDate)

      while (startDay.isBefore(endDay)) {
        if (this.holydays.includes(parseInt(startDay.format('d')))) {
          return false
        }
        startDay = startDay.add(1, 'day')
      }

      return true
    },
    isValidDate(date) {
      if (!date) {
        return false
      }

      const day = dayjs(date)
      const calStartDate = dayjs(this.calStartDate)
      const calEndDate = dayjs(this.calEndDate)

      if (!day.isBetween(calStartDate.subtract(1, 'day'), calEndDate.add(1, 'day'))) {
        return false
      }
      if (this.reservedDates.includes(day.format('YYYY/MM/DD'))) {
        return false
      }

      return true
    },
  },
}
</script>
