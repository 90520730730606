<template>
  <section class="searchbox">
    <div class="searchbox-inner">
      <div class="searchbox-inputarea">
        <div class="searchbox-inputarea-inner">
          <div class="searchbox-inputarea-main">
            <button @click.prevent="$modal.show('select-area')">
              <icon-area-pin />
              <span class="truncate" :class="{ 'text-gray7': !areaLabel }">{{ areaLabel || 'エリア選択なし' }}</span>
            </button>
            <button @click.prevent="$modal.show('date-select')">
              <icon-calendar />
              <span class="truncate" :class="{ 'text-gray7': !datesLabel }">{{ datesLabel || '日付選択なし' }}</span>
            </button>
            <button @click.prevent="$modal.show('capacity-select')">
              <icon-people />
              <span class="truncate" :class="{ 'text-gray7': !capacityLabel }">{{ capacityLabel || '大人1名 1室' }}</span>
            </button>
          </div>
          <div class="searchbox-inputarea-fliter">
            <button @click.prevent="$modal.show('detail-select')">
              <icon-setting />
              絞り込み
            </button>
            <p class="searchbox-inputarea-fliter-text">{{ detailLabel }}</p>
          </div>
        </div>
        <div class="searchbox-inputarea-taglist">
          <div v-if="!!themeList" class="tag" :class="{ checked: !!checkedThemeList }" @click="changeThemeList">
            {{ themeListLabel() }}
          </div>
          <div
            v-for="tag in sortedTags"
            :key="`tag-${tag.id}`"
            class="tag"
            :class="{ checked: checkedTag === tag.id }"
            @click="changeTag(tag.id)"
          >
            {{ tag.name }}
          </div>
        </div>
      </div>
      <div class="searchbox-btn">
        <button class="btn btn-white" @click="search">再検索</button>
      </div>
    </div>
    <Teleport to="body">
      <DateSelectModal
        :current-time="currentTime"
        :start-date="calendarStartDate"
        :end-date="calendarEndDate"
        :check-in-date="checkInDate"
        :check-out-date="checkOutDate"
        @updateValue="handleUpdatedDates"
      ></DateSelectModal>
      <AreaSelectModal
        :areas="areas"
        :checked-areas="checkedAreas"
        :checked-prefs="checkedPrefs"
        @areaChanged="handleAreaChanged"
        @prefChanged="handlePrefChanged"
        @clearArea="handleClearArea"
      ></AreaSelectModal>
      <CapacitySelectModal
        :adults="adults"
        :children="children"
        :preschoolers="preschoolers"
        :rooms="rooms"
        :is-legacy-plan="isLegacyPlan"
        @updateValue="handleUpdatedCapacity"
      ></CapacitySelectModal>
      <DetailSelectModal
        :home-kinds="homeKinds"
        :room-kinds="roomKinds"
        :home-group-kinds="homeGroupKinds"
        :checked-home-kinds="checkedHomeKinds"
        :checked-room-kinds="checkedRoomKinds"
        :is-original="isOriginal"
        :has-kitchen="hasKitchen"
        :has-parking="hasParking"
        :is-parking-free="isParkingFree"
        :is-parking-reservable="isParkingReservable"
        :has-desk-chair="hasDeskChair"
        :can-meet-yamori="canMeetYamori"
        :is-member-only="isMemberOnly"
        :checked-home-group-kinds="checkedHomeGroupKinds"
        :has-auto-approval="hasAutoApproval"
        :has-discount="hasDiscount"
        :has-discount-three-nights="hasDiscountThreeNights"
        :has-discount-seven-nights="hasDiscountSevenNights"
        :favorite-only="favoriteOnly"
        :is-contracted="isContracted"
        :is-legacy-plan="isLegacyPlan"
        :is-unregistered="isUnregistered"
        :icon-original-img-src="iconOriginalImgSrc"
        @checkChanged="handleCheckChanged"
        @homeKindsChanged="handleHomeKindsChanged"
        @roomKindsChanged="handleRoomKindsChanged"
        @hasParkingChanged="handleHasParkingChanged"
        @parkingDetailChanged="handleParkingDetailChanged"
        @homeGroupKindsChanged="handleHomeGroupKindsChanged"
        @hasDiscountChanged="handleHasDiscountChanged"
        @discountDetailChanged="handleDiscountDetailChanged"
        @clearDetail="handleClearDetail"
      ></DetailSelectModal>
    </Teleport>
  </section>
</template>

<script>
import DateSelectModal from '../../shared/components/search/DateSelectModal.vue'
import CapacitySelectModal from '../../shared/components/search/CapacitySelectModal.vue'
import AreaSelectModal from '../../shared/components/search/AreaSelectModal.vue'
import DetailSelectModal from '../../shared/components/search/DetailSelectModal.vue'

import IconAreaPin from '../../shared/icons/AreaPin.vue'
import IconCalendar from '../../shared/icons/CalendarBig.vue'
import IconPeople from '../../shared/icons/People.vue'
import IconSetting from '../../shared/icons/Setting.vue'

import Teleport from 'vue2-teleport'

import SearchBase from '../../shared/components/search/SearchBase.vue'

export default {
  components: {
    DateSelectModal,
    CapacitySelectModal,
    AreaSelectModal,
    DetailSelectModal,
    IconAreaPin,
    IconCalendar,
    IconPeople,
    IconSetting,
    Teleport,
  },
  extends: SearchBase,
}
</script>
