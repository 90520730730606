<template>
  <div>
    <div v-if="isEditing" class="themelist-edit-block" :class="{ 'themelist-edit-error': hasError }">
      <div class="themelist-edit-block-inner mt-2 mb-4">
        <button v-if="heading === null" class="btn themelist-edit-btn" @click.prevent="heading = ''">＋ 見出しを設定する</button>
        <div v-else class="themelist-edit-block-h2-editform">
          <input v-model="heading" type="text" class="form-control" placeholder="見出しを入力" />
          <button class="text-sub text-gray7 ml-3" @click.prevent="heading = null">削除</button>
        </div>
      </div>
      <div class="mb-4">
        <div v-if="visibleSelectedImages.length > 0" v-dragscroll class="themelist-edit-block-images">
          <div v-for="image in visibleSelectedImages" :key="image.id" class="themelist-edit-block-image-item">
            <img :src="image.url" />
            <button class="themelist-edit-block-image-delete" @click.prevent="removeImage(image.id)">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#ffffff"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div class="themelist-edit-block-inner">
          <label class="btn themelist-edit-btn" :class="{ 'themelist-edit-disabled': !isAddableImage }">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="mb-1"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <rect x="3" y="3" width="18" height="18" rx="2" />
              <circle cx="8.5" cy="8.5" r="1.5" />
              <path d="M20.4 14.5L16 10 4 20" />
            </svg>
            {{ selectedImages.length > 0 ? `画像を追加する` : '画像を設定する' }}（最大{{ maxImageCount }}枚）
            <input type="file" accept="image/*" multiple @change="onFileChange" />
          </label>
        </div>
      </div>
      <div class="themelist-edit-block-inner mb-4">
        <ul v-if="visibleSelectedHomes.length > 0" class="themelist-edit-block-homes">
          <li v-for="home in visibleSelectedHomes" :key="home.id">
            <a :href="home.link_url" target="_blank">
              <p>
                <img :src="home.thumb_image_url" class="themelist-edit-block-homes-thumb" />
              </p>
              <div>
                <h3 class="bold text-lg">{{ home.name }}</h3>
                <div class="d-flex lh-sm">
                  <p class="text-xs text-gray7">{{ home.prefecture }}</p>
                  <p class="themelist-edit-block-homes-type text-xs text-gray7">{{ home.kind }}</p>
                </div>
                <p class="themelist-edit-block-homes-pr text-sub mt-2 lh-base">{{ home.pr_statement }}</p>
              </div>
            </a>
            <button class="themelist-edit-block-homes-delete" @click.prevent="removeHome(home.id)">×</button>
          </li>
        </ul>
        <v-select
          v-if="isEditingHome"
          label="id"
          :options="filteredOptions"
          :filterable="false"
          placeholder="家を選択する"
          @search="onSearch"
          @input="onSelected"
        >
          <template slot="no-options">家名を入力して検索</template>
          <template slot="option" slot-scope="option">
            <div class="themelist-edit-block-homes-options">
              <img :src="option.thumb_image_url" />
              <span>{{ option.name }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <span>{{ option.name }}</span>
          </template>
        </v-select>
        <button v-else-if="isAddableHome" class="btn themelist-edit-btn" @click.prevent="isEditingHome = true">
          ＋ 家を追加する
        </button>
      </div>
      <div class="themelist-edit-block-inner">
        <textarea v-model="description" class="form-control" style="height: 150px" placeholder="本文を入力（必須）"></textarea>
      </div>
      <div v-if="hasError" class="text-red text-center mt-4">
        <p v-for="msg in errorMessages" :key="msg">{{ msg }}</p>
      </div>
      <div class="themelist-edit-block-inner mt-4">
        <div class="text-right">
          <button v-if="defaultData.is_saved" class="btn btn-gray text-gray7 px-2 py-2 mr-2" @click.prevent="cancelEditing">
            キャンセル
          </button>
          <button class="btn btn-white px-4 py-2" :class="{ 'themelist-edit-disabled': isLoading }" @click.prevent="onSave">
            保存
          </button>
        </div>
        <div class="text-right mt-2">
          <button v-if="!isFirstComponent" class="text-gray7 text-xs" @click.prevent="removeComponent">このブロックを削除</button>
        </div>
      </div>
    </div>

    <div v-else class="themelist-edit-block">
      <div v-if="heading" class="themelist-edit-block-inner mt-2 mb-4">
        <h2 class="themelist-edit-block-h2">{{ heading }}</h2>
      </div>
      <div v-if="visibleSelectedImages.length > 0" class="mb-4">
        <div v-dragscroll class="themelist-edit-block-images">
          <div v-for="image in visibleSelectedImages" :key="image.id" class="themelist-edit-block-image-item">
            <img :src="image.url" />
          </div>
        </div>
      </div>
      <div class="themelist-edit-block-inner mb-4">
        <ul class="themelist-edit-block-homes">
          <li v-for="home in visibleSelectedHomes" :key="home.id">
            <a :href="home.link_url" class="">
              <p>
                <img :src="home.thumb_image_url" class="themelist-edit-block-homes-thumb" />
              </p>
              <div>
                <h3 class="bold text-lg">{{ home.name }}</h3>
                <div class="d-flex lh-sm">
                  <p class="text-xs text-gray7">{{ home.prefecture }}</p>
                  <p class="themelist-edit-block-homes-type text-xs text-gray7">{{ home.kind }}</p>
                </div>
                <p class="text-sub mt-2 lh-base">{{ home.pr_statement }}</p>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div class="themelist-edit-block-inner">
        <p style="word-wrap: break-word; white-space: pre-wrap" v-text="description"></p>
      </div>
      <div class="themelist-edit-block-inner mt-4">
        <div class="text-right text-gray7 text-sm">
          <button :class="{ 'themelist-edit-disabled': editDisabled }" @click.prevent="enterEditing">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#969696"
              stroke-width="1.8"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="mb-1"
            >
              <polygon points="16 3 21 8 8 21 3 21 3 16 16 3"></polygon>
            </svg>
            このブロックを編集
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import _ from 'lodash'
import axios from 'axios'
import 'vue-select/dist/vue-select.css'

export default {
  components: {
    vSelect,
  },
  props: {
    defaultData: {
      type: Object,
      required: true,
    },
    position: {
      type: Number,
      required: true,
    },
    editDisabled: {
      type: Boolean,
      default: false,
    },
    editApiUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      maxImageCount: 10,
      maxHomeCount: 10,
      isEditing: false,
      isEditingHome: false,
      errorMessages: [],
      heading: null,
      description: null,
      options: [],
      selectedHomes: [],
      selectedImages: [],
      isLoading: false,
    }
  },
  computed: {
    isFirstComponent() {
      return this.position < 1
    },
    filteredOptions() {
      const selectedHomeIds = this.visibleSelectedHomes.map((h) => h.id)
      return this.options.filter((h) => !selectedHomeIds.includes(h.id))
    },
    isAddableImage() {
      return this.visibleSelectedImages.length < this.maxImageCount
    },
    isAddableHome() {
      return this.visibleSelectedHomes.length < this.maxHomeCount
    },
    visibleSelectedHomes() {
      return this.selectedHomes.filter((h) => !h.is_deleted)
    },
    visibleSelectedImages() {
      return this.selectedImages.filter((i) => !i.is_deleted)
    },
    hasError() {
      return this.errorMessages.length > 0
    },
  },
  watch: {
    defaultData: {
      handler(newData) {
        this.heading = newData.heading !== '' ? newData.heading : null
        this.description = newData.description
        this.selectedHomes = _.cloneDeep(newData.homes)
        this.selectedImages = _.cloneDeep(newData.images)
      },
      deep: true,
    },
  },
  mounted() {
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }
    if (!this.defaultData.is_saved) {
      this.enterEditing()
    } else {
      this.initializeData()
    }
    if (this.selectedHomes.length < 1) {
      this.isEditingHome = true
    }
  },
  methods: {
    initializeData() {
      this.heading = this.defaultData.heading !== '' ? this.defaultData.heading : null
      this.description = this.defaultData.description
      this.selectedHomes = _.cloneDeep(this.defaultData.homes)
      this.selectedImages = _.cloneDeep(this.defaultData.images)
      this.errorMessages = []
    },
    enterEditing() {
      this.isEditing = true
      this.$emit('enter-editing')
    },
    exitEditing() {
      this.isEditing = false
      this.$emit('exit-editing')
    },
    cancelEditing() {
      this.isEditing = false
      this.initializeData()
      this.$emit('exit-editing')
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.search(loading, search, this)
      }
    },
    search: _.debounce((loading, search, vm) => {
      axios.get('/api/v1/web/homes/search', { params: { q: search } }).then((response) => {
        vm.options = response.data
        loading(false)
      })
    }, 350),
    onSelected(value) {
      this.selectedHomes.push({ ...value, is_saved: false })
      this.isEditingHome = false
    },
    removeHome(id) {
      const removedHome = this.selectedHomes.find((h) => h.id === id)
      if (removedHome.is_saved) {
        this.$set(removedHome, 'is_deleted', true)
      } else {
        this.selectedHomes.splice(
          this.selectedHomes.findIndex((h) => h.id === id),
          1
        )
      }
    },
    onFileChange(e) {
      e.target.files.forEach((file, index) => {
        const leftImageCount = this.maxImageCount - this.visibleSelectedImages.length
        if (index >= leftImageCount) return
        this.selectedImages.push({
          id: this.$uuid.v4(),
          is_saved: false,
          image_file: file,
          url: URL.createObjectURL(file),
        })
      })
    },
    removeImage(id) {
      const removedImage = this.selectedImages.find((i) => i.id === id)
      if (removedImage.is_saved) {
        this.$set(removedImage, 'is_deleted', true)
      } else {
        this.selectedImages.splice(
          this.selectedImages.findIndex((i) => i.id === id),
          1
        )
      }
    },
    hasChanges() {
      return (
        !_.isEqual(this.selectedHomes, this.defaultData.homes) ||
        !_.isEqual(this.selectedImages, this.defaultData.images) ||
        this.heading !== this.defaultData.heading ||
        this.description !== this.defaultData.description
      )
    },
    async onSave() {
      this.isLoading = true
      if (!this.hasChanges()) {
        this.errorMessages = []
        this.exitEditing()
      } else if (this.defaultData.is_saved) {
        await this.updateComponent()
      } else {
        await this.createComponent()
      }
      this.isLoading = false
    },
    async createComponent() {
      await axios
        .post(`${this.editApiUrl}/theme_list_components/`, this.buildParams())
        .then(() => {
          this.errorMessages = []
          this.$emit('refresh-data')
          this.exitEditing()
        })
        .catch((error) => {
          this.errorMessages = error.response.data.messages
        })
    },
    async updateComponent() {
      await axios
        .patch(`${this.editApiUrl}/theme_list_components/${this.defaultData.id}`, this.buildParams())
        .then(() => {
          this.errorMessages = []
          this.$emit('refresh-data')
          this.exitEditing()
        })
        .catch((error) => {
          this.errorMessages = error.response.data.messages
        })
    },
    buildParams() {
      const params = new FormData()
      params.append('theme_list_component[heading]', this.heading || '')
      params.append('theme_list_component[description]', this.description || '')
      this.selectedImages.forEach((image) => {
        params.append(`images[][id]`, image.id)
        params.append(`images[][is_saved]`, image.is_saved)
        params.append(`images[][is_deleted]`, image.is_deleted || false)
        params.append(`images[][image_file]`, image.image_file)
      })
      this.selectedHomes.forEach((home) => {
        params.append('homes[][id]', home.id)
        params.append('homes[][is_saved]', home.is_saved)
        params.append('homes[][is_deleted]', home.is_deleted || false)
      })
      return params
    },
    removeComponent() {
      this.$emit('remove-component', this.defaultData.id)
      this.$emit('exit-editing')
    },
  },
}
</script>

<style lang="scss">
input[type='file'] {
  display: none;
}

.vs__dropdown-toggle {
  padding: 1em 0;
}

.vs__search {
  margin: 0;
}

.vs-disabled-option {
  pointer-events: none;
  opacity: 0.8;
}
</style>
