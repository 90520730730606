<template>
  <div v-if="roomId" :class="className" :style="genStyle()">
    <a v-if="className === 'stop' && isFromAdmin" :href="reservationPath + id">
      <span>{{ `休止中(${blockReason})` }}</span>
    </a>
    <span v-else-if="className === 'stop' && !isFromAdmin">
      {{ `休止中(${blockReason})` }}
    </span>
    <a v-if="className === 'requested'" :href="reservationPath + id">
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#950008"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="12" y1="8" x2="12" y2="12"></line>
          <line x1="12" y1="16" x2="12.01" y2="16"></line>
        </svg>
        [未承認] {{ start }} 〜 {{ end }}
      </span>
      <span>{{ userName }}</span>
    </a>
    <a v-if="className === 'stay'" :href="reservationPath + id">
      <span>{{ start }}～{{ end }}</span>
      <span>{{ userName }}</span>
    </a>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      required: true,
    },
    roomId: {
      type: Number,
      required: true,
    },
    userName: {
      type: String,
      required: true,
    },
    checkInDate: {
      type: String,
      required: true,
    },
    checkOutDate: {
      type: String,
      required: true,
    },
    blockReason: {
      type: String,
      default: null,
    },
    calendar: {
      type: Array,
      required: true,
    },
    rooms: {
      type: Array,
      required: true,
    },
    reservationPath: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      row: 0,
      column: 1,
      span: 0,
      start: '',
      end: '',
    }
  },
  computed: {
    isFromAdmin() {
      return this.reservationPath.startsWith('/admin')
    },
  },
  mounted: function () {
    this.setRow(this.rooms)
    this.setColumn(this.checkInDate, this.calendar)
    this.setSpan(this.checkInDate, this.checkOutDate, this.calendar)
    this.setDate(this.checkInDate, this.checkOutDate)
  },
  methods: {
    setRow(rooms) {
      if (!this.roomId) {
        this.row = rooms.length + 1
      } else {
        rooms.map((r, i) => {
          if (this.roomId == r.id) {
            this.row = i + 1
          }
        })
      }
    },
    setColumn(checkInDate, calendar) {
      /* check_in_date を判定して grid-column スタート位置 */
      calendar.map((c, i) => {
        if (c.date == checkInDate) {
          // 1(枠外) / 2(先頭) ~ 7(最後) / 8(枠外)
          this.column = i + 2
        }
        /* default = 1(枠外) */
      })
    },
    setSpan(checkInDate, checkOutDate, calendar) {
      let span
      if (this.column === 1) {
        /* check_in_date が枠外の場合 */
        span = dayjs(checkOutDate).diff(dayjs(calendar[0].date), 'day') + 1
      } else {
        /* check_in_date ~ check_out_date が 何日間か span */
        span = dayjs(checkOutDate).diff(dayjs(checkInDate), 'day')
      }
      /* 最大 (1週間の場合は8) */
      if (span > this.calendar.length) {
        span = this.calendar.length + 1
      }
      this.span = span
    },
    setDate(checkInDate, checkOutDate) {
      this.start = dayjs(checkInDate).format('M/D')
      this.end = dayjs(checkOutDate).format('M/D')
    },
    genStyle() {
      // 'grid-row: ' + row + ' / ' + (row + 1) + '; grid-column: ' + column + ' / span ' + span + ';'

      let styles = []
      // Grid Position | Size
      styles.push(`grid-row: ${this.row} / ${this.row + 1};`)
      styles.push(`grid-column: ${this.column} / span ${this.span};`)

      // Indent text when outter space
      if (this.column == 1) {
        // To avoid Infinity
        // The ratio function is 100%[width] / this.span[size] * (4 / 5)[positon]
        let ratio = 0
        switch (this.span) {
          case 1:
            ratio = 60
            break
          case 2:
            ratio = 30
            break
          case 3:
            ratio = 20
            break
          case 4:
            ratio = 15
            break
          case 5:
            ratio = 12
            break
          case 6:
            ratio = 10
            break
          case 7:
            ratio = 8.57
            break
          case 8:
            ratio = 7.5
            break
        }
        styles.push(`padding-left: calc(${ratio}%)`)
      }

      return styles.join(' ')
    },
  },
}
</script>
