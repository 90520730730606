<template>
  <div class="calendar-timeline__dates">
    <div
      v-for="(month, index) in months"
      :key="month.yearMonth"
      class=""
      :class="{
        'calendar-timeline__month-label': index === 0,
        'calendar-timeline__month': index !== 0,
      }"
      :style="`grid-area: 1 / ${month.startIndex + 1} / 2 / ${month.endIndex + 2}`"
    >
      <span ref="month">{{ index === 0 ? currentFullYearMonth : month.yearMonth }}</span>
    </div>
    <div
      v-for="(date, index) in dates"
      :key="date.unix()"
      class="calendar-timeline__day"
      :style="`grid-area: 2 / ${index + 1} / 3 / ${index + 2}`"
    >
      <span>{{ date.format('DD') }}</span>
      <span class="calendar-timeline__dd" :data-day="date.day()" :data-holiday="isHoliday(date) ? true : null">
        {{ date.format('dd') }}
      </span>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import 'dayjs/locale/ja'
import holidays from 'holiday-jp'
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')
dayjs.locale('ja')

export default {
  props: {
    dates: {
      type: Array,
      required: true,
    },
    scrollX: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currentMonth: null,
    }
  },
  computed: {
    currentFullYearMonth() {
      return this.currentMonth?.fullYearMonth
    },
    months() {
      return this.dates.reduce((months, date, index) => {
        const currentMonth = date.format('YYYY-MM')
        const lastMonth = months.length ? months[months.length - 1].month : null

        if (currentMonth !== lastMonth) {
          const yearMonth =
            index === 0 || date.year() !== this.dates[index - 1].year() ? date.format('YYYY年M月') : date.format('M月')

          months.push({
            yearMonth: yearMonth,
            fullYearMonth: date.format('YYYY年M月'),
            month: currentMonth,
            startIndex: index,
            endIndex: index,
          })
        } else {
          months[months.length - 1].endIndex = index
        }

        return months
      }, [])
    },
  },
  watch: {
    scrollX: {
      handler(newX) {
        const monthElements = this.$refs.month
        if (!monthElements) return
        const monthOffsets = [...monthElements.map((monthElement) => monthElement.offsetLeft), Infinity]
        const containerOffset = monthElements[0].getBoundingClientRect().x
        const nextMonthIndex = monthOffsets.findIndex((offset) => newX + containerOffset < offset)
        this.currentMonth = this.months[nextMonthIndex - 1]
      },
      immediate: true,
    },
    dates: {
      handler() {
        this.currentMonth = this.months[0]
      },
    },
  },
  mounted() {
    this.currentMonth = this.months[0]
  },
  methods: {
    isHoliday(date) {
      return holidays.isHoliday(new Date(date.format('YYYY-MM-DD')))
    },
  },
}
</script>

<style lang="scss" scoped>
.calendar-timeline {
  &__dates {
    display: grid;
    grid-template-rows: 24px auto;
    grid-template-columns: repeat(auto-fit, 40px);
    grid-auto-flow: column;
    gap: 1px;
    height: 75px;
    @media (width >= 768px) {
      grid-template-columns: repeat(auto-fit, 60px);
      height: 95px;
    }
  }

  &__day {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (width <= 767px) {
      width: 40px;
      font-size: 12px;
    }
    @media (width >= 768px) {
      width: 60px;
    }
  }

  &__dd {
    font-size: 10px;
    color: #bcbcbc;
    @media (width >= 768px) {
      font-size: 12px;
    }

    &[data-day='0'] {
      font-weight: bold;
      color: #e04949;
    }

    &[data-day='6'] {
      font-weight: bold;
      color: #7ac9b6;
    }

    &[data-holiday='true'] {
      font-weight: bold;
      color: #e04949;
    }
  }

  &__month-label {
    position: sticky;
    left: 0;
    display: flex;
    align-items: center;
    font-weight: 700;
    @media (width <= 767px) {
      font-size: 12px;
    }

    & span {
      padding-left: 1em;
      white-space: nowrap;
      background: #fff;
      box-shadow: 10px 0 8px 5px #fff;
    }
  }

  &__month {
    display: flex;
    align-items: center;
    margin-left: 1em;
    font-weight: 700;
    @media (width <= 767px) {
      font-size: 12px;
    }
  }
}
</style>
