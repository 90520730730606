<template>
  <div>
    <p v-if="includePrincipal">会員本人</p>
    <div v-for="(selectBox, index) in selectBoxes" :key="selectBox.id" class="d-flex mt-3">
      <select :id="index" class="form-select" @change.prevent="selected">
        <option>{{ isOnlyForPreschooler(index) ? '選択する(幼児のみ)' : '選択する' }}</option>
        <option
          v-for="user in acceptableUsers(index, selectBox.accompany_user_id)"
          :key="user.id"
          :value="user.id"
          :selected="user.id === selectBox.accompany_user_id"
        >
          {{ user.last_name }} {{ user.first_name }}（{{ user.age_kind }}）
        </option>
      </select>
      <button
        v-if="includePrincipal || index > 0"
        type="button"
        class="bold text-alert text-sub ml-2"
        style="white-space: nowrap"
        @click.prevent="removeSelectBox(index)"
      >
        削除
      </button>
    </div>
    <div v-if="canAddSelectBox" class="text-center mt-3">
      <button class="btn btn-text2 text-sub" @click.prevent="addSelectBox()">この部屋に同伴者を追加</button>
    </div>

    <input type="hidden" :name="formName" :value="selectedUsers.join(',')" />

    <modal
      :name="`${id}-info-modal`"
      height="auto"
      classes="info-modal"
      style="z-index: 1050; display: flex; align-items: center; justify-content: center; height: 100%"
    >
      <div class="modal-box d-flex justify-content-center align-items-center">
        <div class="modal-box-inner">
          <div class="modal-box-body">
            <p>
              同伴者が未登録です。登録を済ませてからご予約ください。登録申請は
              <a
                href="https://address-membersupport.zendesk.com/hc/ja/articles/15085997814297"
                class="btn-text2 text-normal underline"
                target="_blank"
                rel="noopener"
              >
                こちら
              </a>
              からお願いします。
              <br />
              ※申請から登録まで2営業日ほどかかります。
            </p>
            <div class="modal-box-close">
              <button @click.prevent="$modal.hide(`${id}-info-modal`)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#67635e"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    formName: {
      type: String,
      required: true,
    },
    maxUsers: {
      type: Number,
      required: true,
    },
    includePrincipal: {
      type: Boolean,
      default: false,
    },
    accompanyUsers: {
      type: Array,
      default: () => [],
    },
    selectedUserIds: {
      type: Array,
      default: () => [],
    },
    acceptableSexType: {
      type: String,
      default: 'all',
    },
    isDormitory: {
      type: Boolean,
      default: false,
    },
    alreadySelectedUserIds: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      selectBoxes: [],
      selectedUsers: [],
      showModal: false,
    }
  },
  computed: {
    maxAcceptableUsers() {
      if (this.includePrincipal) {
        return this.maxUsers - 1
      } else {
        return this.maxUsers
      }
    },
    maxAcceptableUsersWithPreschooler() {
      const preschoolerCapacity = this.isDormitory ? 0 : Math.ceil(this.maxUsers / 2.0)
      return this.maxAcceptableUsers + preschoolerCapacity
    },
    canAddSelectBox() {
      return this.selectBoxes.length < this.maxAcceptableUsersWithPreschooler
    },
    selectedUserIdsWithOtherRoom() {
      return this.selectedUsers.concat(this.alreadySelectedUserIds)
    },
  },
  watch: {
    selectedUserIds: {
      handler(newIds, oldIds) {
        if (JSON.stringify(oldIds) !== JSON.stringify(newIds)) {
          this.setSelectedUsers(newIds)
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.setSelectedUsers(this.selectedUserIds)
  },
  methods: {
    setSelectedUsers(ids) {
      this.selectBoxes = ids.map((id) => {
        return { id: this.$uuid.v4(), accompany_user_id: id }
      })
      if (!this.includePrincipal && this.selectBoxes.length < 1) {
        this.selectBoxes = [{ id: this.$uuid.v4(), accompany_user_id: null }]
      }
      this.selectedUsers = this.selectBoxes.filter((box) => !!box['accompany_user_id']).map((box) => box['accompany_user_id'])
    },
    isOnlyForPreschooler(index) {
      return index >= this.maxAcceptableUsers
    },
    acceptableUsers(index, selfId) {
      let users = this.accompanyUsers.filter((user) => user.id === selfId || !this.selectedUserIdsWithOtherRoom.includes(user.id))

      if (this.isOnlyForPreschooler(index)) {
        return users.filter((user) => user.age_kind === '幼児')
      }

      return users
    },
    addSelectBox() {
      if (this.accompanyUsers.length < 1) {
        this.$modal.show(`${this.id}-info-modal`)
      } else if (this.canAddSelectBox) {
        this.selectBoxes.push({
          id: this.$uuid.v4(),
          accompany_user_id: null,
        })
      }
    },
    removeSelectBox(index) {
      this.selectBoxes.splice(index, 1)
      this.updateSelectedUsers()
    },
    selected(e) {
      const selectBoxIndex = e.target.id
      const selectedUserId = e.target.value
      if (selectedUserId === '選択する') {
        this.selectBoxes[selectBoxIndex]['accompany_user_id'] = null
      } else {
        this.selectBoxes[selectBoxIndex]['accompany_user_id'] = parseInt(selectedUserId)
      }
      this.updateSelectedUsers()
    },
    updateSelectedUsers() {
      this.selectedUsers = this.selectBoxes.filter((box) => !!box['accompany_user_id']).map((box) => box['accompany_user_id'])
      this.$emit('update-selected', this.selectedUsers)
    },
  },
}
</script>

<style lang="scss">
.vm--modal.info-modal {
  top: initial !important;
  left: initial !important;
  width: initial !important;
  height: initial !important;
}
</style>
