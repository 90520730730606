<template>
  <div>
    <div v-if="images.length === 1" class="themelist-show-block-images image-single">
      <div class="themelist-show-block-image-item">
        <button v-for="(image, index) in images" :key="image" class="open-lightbox" @click.prevent="openLightBox(index)">
          <img v-lazy="image" />
        </button>
      </div>
    </div>
    <slick v-else ref="slick" :options="slickOptions" class="themelist-show-block-images themelist-images-carousel">
      <div v-for="(image, index) in images" :key="image" class="themelist-show-block-image-item">
        <button class="open-lightbox" @click.prevent="openLightBox(index)">
          <img v-lazy="image" />
        </button>
      </div>
    </slick>
    <light-box ref="lightbox" :media="imagesForLightBox" :show-light-box="false"></light-box>
  </div>
</template>

<script>
import Slick from 'vue-slick'
import LightBox from 'vue-image-lightbox'
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css'

export default {
  components: { Slick, LightBox },
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      slickOptions: {
        infinite: false,
        variableWidth: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        responsive: [
          {
            breakpoint: 768,
            settings: 'unslick',
          },
        ],
      },
    }
  },
  computed: {
    imagesForLightBox() {
      return this.images.map((imageUrl) => {
        return {
          thumb: imageUrl,
          src: imageUrl,
        }
      })
    },
  },
  methods: {
    openLightBox(index) {
      this.$refs.lightbox.showImage(index)
    },
  },
}
</script>

<style lang="scss" scoped>
button.open-lightbox {
  position: relative;

  &::after {
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
    z-index: 2;
    display: block;
    width: 40px;
    height: 40px;
    content: '';
    background: url('~official/icon_zoom.png');
    background-size: cover;
    @media (width <= 767px) {
      width: 30px;
      height: 30px;
    }
  }
}
</style>
