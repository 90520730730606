<template>
  <div
    id="roomSearchModal"
    class="room-search-modal modal fade"
    tabindex="-1"
    aria-labelledby="roomSearchModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">{{ homeName }}の空室検索</div>
          <button ref="closeBtn" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <RoomSearchForm
            ref="roomSearchFormSP"
            :default-form-data="defaultFormData"
            :cal-start-date="calStartDate"
            :cal-end-date="calEndDate"
            :reserved-dates="reservedDates"
            :holydays="holydays"
            :min-days="minDays"
            :disable-room-count="!enableMultiReserve"
            :is-contracted="isContracted"
          ></RoomSearchForm>
        </div>
        <div class="modal-footer-room-reserve-calendar" :class="isLegacyPlan ? '' : 'plan2023'">
          <div v-if="isLegacyPlan && !!registrationTickets && registrationTickets.length > 1">
            <div class="d-flex">
              <p class="text-center mr-3 pb-0">この家に使えるチケット残数</p>
              <input id="check-tickets-2" type="checkbox" class="check-tickets-trigger" />
              <label class="check-tickets-btn" for="check-tickets-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#CF761D"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </label>
              <div class="tickets-datalist">
                <p class="text-bold">この家に使えるチケット残数</p>
                <ul class="text-sub mt-2 mb-2">
                  <li v-for="registrationTicket in registrationTickets" :key="registrationTicket.id" class="flex">
                    <p class="text-gray2">{{ registrationTicket.period_str }}</p>
                    <p class="text-bold">{{ registrationTicket.tickets_str }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div v-else-if="isLegacyPlan && !!registrationTickets && registrationTickets.length === 1">
            <p class="text-center mr-4 pb-0">
              この家に使えるチケット残数
              <span class="userinfo-days text-bold">{{ registrationTickets[0]['tickets_str'] }}</span>
            </p>
            <p v-if="!!upgradeOffer">
              <a :href="upgradeOffer.url + '?from=room-search-sp'" target="_blank" rel="noopener" class="btn-text arrow-r-orange">
                {{ upgradeOffer.text }}
              </a>
            </p>
          </div>

          <div v-else-if="isLegacyPlan">
            <p class="text-center mr-4 pb-0">
              この家に使えるチケット残数
              <span class="userinfo-days text-bold">なし</span>
            </p>
          </div>

          <div v-else class="d-flex align-items-center">
            <p>予約チケット残数</p>
            <p class="text-bold fs-4 pl-3">{{ ticketAmounts }}</p>
          </div>

          <button type="button" class="btn btn-primary" @click="searchRoom">部屋検索</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RoomSearchForm from './RoomSearchForm'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'

export default {
  components: { RoomSearchForm },
  props: {
    homeName: {
      type: String,
      required: true,
    },
    defaultFormData: {
      type: Object,
      required: true,
    },
    calStartDate: {
      type: String,
      required: true,
    },
    calEndDate: {
      type: String,
      required: true,
    },
    reservedDates: {
      type: Array,
      default: () => [],
    },
    holydays: {
      type: Array,
      required: true,
    },
    registrationTickets: {
      type: Array,
      default: () => [],
    },
    ticketAmounts: {
      type: Number,
      default: 0,
    },
    upgradeOffer: {
      type: Object,
      default: () => {},
    },
    isSignedIn: {
      type: Boolean,
      required: true,
    },
    isLegacyPlan: {
      type: Boolean,
      required: true,
    },
    isContracted: {
      type: Boolean,
      required: true,
    },
    enableMultiReserve: {
      type: Boolean,
      required: false,
    },
    minDays: {
      type: Number,
      required: true,
    },
  },
  methods: {
    searchRoom() {
      const url = this.$refs.roomSearchFormSP.getSearchUrl()
      url.hash = '#room'
      window.location.href = url.href

      this.$refs.closeBtn.click()
    },
    formatDate(date) {
      return dayjs(date).format('M/D')
    },
  },
}
</script>
