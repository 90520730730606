<template>
  <div class="contentmenu">
    <div class="contentmenu-btn">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#67635e"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="contentmenu-btn-svg"
      >
        <circle cx="12" cy="12" r="1"></circle>
        <circle cx="19" cy="12" r="1"></circle>
        <circle cx="5" cy="12" r="1"></circle>
      </svg>
    </div>
    <div v-show="isButtonVisible" class="contentmenu-inner">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isButtonVisible: false,
    }
  },
  mounted() {
    document.addEventListener('click', this.documentClickHandler)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.documentClickHandler)
  },
  methods: {
    documentClickHandler(event) {
      if (this.$el.contains(event.target) && event.target.closest('.contentmenu-btn-svg')) {
        this.isButtonVisible = !this.isButtonVisible
      } else {
        this.isButtonVisible = false
      }
    },
  },
}
</script>
