function formatRequest(e) {
  e.preventDefault()
  let url = e.target.href
  const baseUri = e.target.baseURI
  let query = baseUri.split('?')[1]
  if (query) {
    query = query.replace('check_in=', 'check_in_date=')
    query = query.replace('check_out=', 'check_out_date=')
    window.location.href = url + '&' + query
  }
}

function addFormatEvent() {
  const reservationButton = document.getElementsByClassName('submit-reservation')
  reservationButton.forEach((button) => {
    button.addEventListener('click', (e) => {
      formatRequest(e)
    })
  })
}

document.addEventListener('ajax:complete', () => {
  addFormatEvent()
})

document.addEventListener('DOMContentLoaded', () => {
  addFormatEvent()
})

// 部屋画像modal
function modalOpen(btn) {
  const wrapper = document.querySelector('.image-modal-wrapper')
  const modalId = btn.dataset.modal
  const modal = document.querySelector(`.image-modal[data-modal="${modalId}"]`)
  modal.insertAdjacentHTML('beforeend', '<i class="image-modal-bg"></i>')
  modal.classList.add('is-open')
  const top = window.scrollY
  wrapper.classList.add('is-modalopen')
  wrapper.style.top = `-${top}px`
  const modalbg = document.querySelector('.image-modal-bg')
  modalbg.addEventListener('click', () => {
    modalClose()
  })
}
function modalClose() {
  const wrapper = document.querySelector('.image-modal-wrapper')
  const modal = document.querySelector(`.image-modal.is-open`)
  const getY = (callback) => {
    modal.classList.remove('is-open')
    const modalbg = document.querySelector('.image-modal-bg')
    const top = wrapper.style.top
    const y = top.slice(1, -2)
    wrapper.style.top = ''
    wrapper.classList.remove('is-modalopen')
    modalbg.parentNode.removeChild(modalbg)
    callback(y)
  }
  getY((y) => {
    window.scrollTo(0, y)
  })
}

window.addEventListener('load', function () {
  const openbtns = document.querySelectorAll('.image-modal-open')
  const closebtns = document.querySelectorAll('.image-modal-close')
  openbtns.forEach((openbtn) => {
    openbtn.addEventListener('click', (event) => {
      const btn = event.currentTarget
      modalOpen(btn)
    })
  })
  closebtns.forEach((closebtn) => {
    closebtn.addEventListener('click', () => {
      modalClose()
    })
  })
})
