<template>
  <div class="homedetail-imagesmodal">
    <button class="homedetail-modal-close" @click="$emit('close-modal')">
      <div class="homedetail-modal-close-btn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#67635e"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </div>
    </button>
    <div class="homedetail-imagesmodal-grid">
      <div
        v-for="image in images"
        :key="image.id"
        class="homedetail-imagesmodal-grid-item"
        @click="$modal.show(`image-modal-${image.id}`)"
      >
        <img :ref="`image-${image.id}`" :src="image.src" alt="image.alt" />
        <Teleport to="body">
          <div class="image-gallery-modal">
            <modal
              :name="`image-modal-${image.id}`"
              height="100%"
              style="display: flex; align-items: center; justify-content: center; height: 100%"
            >
              <div class="image-gallery-modal-container">
                <button type="button" class="modal-closebtn" @click.prevent="$modal.hide(`image-modal-${image.id}`)"></button>
                <img v-lazy="image.src" alt="image.alt" />
              </div>
            </modal>
          </div>
        </Teleport>
      </div>
    </div>
  </div>
</template>

<script>
import Teleport from 'vue2-teleport'

export default {
  components: { Teleport },
  props: {
    images: {
      type: Array,
      required: true,
    },
    defaultImageId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {}
  },
  mounted() {
    if (this.defaultImageId) {
      const target = this.$refs[`image-${this.defaultImageId}`]
      if (!target) return

      setTimeout(() => {
        if (target instanceof Array) {
          target[0].scrollIntoView({ behavior: 'smooth', block: 'center' })
        } else {
          target.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      })
    }
  },
}
</script>

<style lang="scss">
.image-gallery-modal-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-gallery-modal-container {
  position: relative;

  & img {
    width: 100%;
  }
}

.image-gallery-modal {
  .vm--modal {
    top: initial !important;
    left: initial !important;
    width: initial !important;
    height: initial !important;
    background-color: initial !important;
    box-shadow: initial !important;
  }

  .vm--container {
    z-index: 1050 !important;
  }

  .vm--overlay {
    background-color: rgb(0 0 0 / 100%) !important;
  }
}
</style>
