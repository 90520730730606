<template>
  <div class="home-calendar">
    <div ref="room-box" class="home-calendar__rooms">
      <div v-for="room in rooms" :key="room.id" class="home-calendar__room-name">
        <a :href="`#room-${room.id}`">{{ room.name }}</a>
      </div>
    </div>
    <div class="home-calendar__body" @scroll="handleScroll">
      <calendar-timeline :dates="dates" :scroll-x="scrollX" />
      <div v-if="!isLoading" class="home-calendar__timelines">
        <div
          v-for="(room, index) in rooms"
          :key="`room${index}`"
          class="home-calendar__timeline"
          :style="`grid-area: ${index + 1} / 1 / ${index + 2} / -1`"
        >
          {{ /* 部屋：空室のボタン */ }}
          <button
            v-for="date in reservableDates(room)"
            :key="`${index}${date.unix()}`"
            class="home-calendar__booking--vacancy"
            :style="`grid-area: 1 / ${daysUntil(date)} / span 1 / span 1`"
            @click.prevent="openModal(room.id, date)"
          ></button>
          {{ /* 部屋：予約 */ }}
          <div
            v-for="reservation in roomBookings[room.id]['reservations']"
            :key="reservation.id"
            :class="{
              'home-calendar__booking--reserved': !reservation.obfuscated_id,
              'home-calendar__booking--ownreserved': reservation.obfuscated_id,
            }"
            :style="`grid-area: 1 / ${reservation.days_until_start + 1} / span 1 / span ${reservation.span}`"
            @click.prevent="openReservation(reservation.obfuscated_id)"
          ></div>
          {{ /* 部屋：予約休止 */ }}
          <div
            v-for="block in roomBookings[room.id]['block_reservations']"
            :key="block.id"
            class="home-calendar__booking--blocked"
            :style="`grid-area: 1 / ${block.days_until_start + 1} / span 1 / span ${block.span}`"
          >
            <span>休止</span>
          </div>
          {{ /* 部屋：予約可能期間外 */ }}
          <div
            class="home-calendar__booking--paused"
            :style="`grid-area: 1 / ${daysUntil(roomBookings[room.id]['calendar_end_date']) + 2} / span 1 / -1`"
          >
            <span>予約可能期間外</span>
          </div>
          {{ /* 部屋：予約対象外 */ }}
          <div
            v-if="roomBookings[room.id].unavailable"
            class="home-calendar__booking--unavailable"
            :style="`grid-area: 1 / 1 / -1 / -1;`"
          >
            <span>{{ roomBookings[room.id].unavailable_reason }}</span>
          </div>
        </div>
        {{ /* 家：定休日 */ }}
        <div
          v-for="day in holidayDays"
          :key="`holiday${day}`"
          class="home-calendar__booking--holiday"
          :style="`grid-area: 1 / ${day + 1} / ${roomCount} / span 1`"
        >
          <span>定休</span>
        </div>
        {{ /* 家：予約開始日 */ }}
        <div
          v-if="homeStartDate"
          class="home-calendar__booking--paused"
          :style="`grid-area: 1 / 1 / ${roomCount} / ${daysUntil(homeStartDate) + 1}`"
        >
          <span>準備中</span>
        </div>
        {{ /* 家：予約終了日 */ }}
        <div
          v-if="homeEndDate"
          class="home-calendar__booking--paused"
          :style="`grid-area: 1 / ${daysUntil(homeEndDate) + 2} / ${roomCount} / -1`"
        >
          <span>提供終了</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import throttle from 'lodash.throttle'
import CalendarTimeline from './CalendarTimeline.vue'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import 'dayjs/locale/ja'
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)
dayjs.tz.setDefault('Asia/Tokyo')
dayjs.locale('ja')

export default {
  components: { CalendarTimeline },
  props: {
    currentDate: {
      type: String,
      required: true,
    },
    rooms: {
      type: Array,
      required: true,
    },
    bookingApiUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      dates: [],
      scrollX: 0,
      holidays: [],
      startDate: null,
      endDate: null,
      reserveFrom: null,
      calenderEndDate: null,
      roomBookings: {},
    }
  },
  computed: {
    currentDateObject() {
      return dayjs(this.currentDate)
    },
    roomCount() {
      return this.rooms.length + 1
    },
    holidayDays() {
      return this.dates
        .filter((date) => {
          return this.holidays.includes(date.day()) && date.isBefore(dayjs(this.calenderEndDate).subtract(7, 'days'))
        })
        .map((date) => date.diff(this.currentDateObject, 'day') + 1)
    },
    homeStartDate() {
      if (this.startDate) {
        const startDate = dayjs(this.startDate)
        return startDate.isSameOrAfter(this.currentDate) ? startDate : null
      } else {
        return null
      }
    },
    homeEndDate() {
      if (this.startDate) {
        const endDate = dayjs(this.endDate)
        const calenderEndDate = dayjs(this.calenderEndDate).subtract(1, 'days')
        return endDate.isBefore(calenderEndDate) ? endDate : null
      } else {
        return null
      }
    },
  },
  created() {
    this.handleScroll = throttle(this.handleScroll, 100)
  },
  async mounted() {
    await this.fetchData()
    this.buildDates()
  },
  methods: {
    buildDates() {
      const startDate = dayjs(this.currentDate)
      const endDate = dayjs(this.calenderEndDate)
      const daysCount = endDate.diff(startDate, 'day')
      this.dates = Array.from({ length: daysCount }, (_, i) => startDate.add(i, 'day'))
    },
    async fetchData() {
      await axios.get(this.bookingApiUrl).then((response) => {
        this.holidays = response.data.holidays
        this.startDate = response.data.reservation_start_date
        this.endDate = response.data.reservation_end_date
        this.reserveFrom = dayjs(response.data.reserve_from)
        this.calenderEndDate = response.data.calendar_end_date
        this.roomBookings = response.data.rooms
        this.isLoading = false
      })
    },
    handleScroll(event) {
      this.scrollX = event.target.scrollLeft
    },
    reservableDates(room) {
      if (this.roomBookings[room.id].unavailable) return []
      return this.dates.filter((date) => date.isAfter(this.reserveFrom))
    },
    daysUntil(date) {
      return dayjs(date).diff(this.currentDateObject, 'day') + 1
    },
    openModal(roomId, date) {
      this.$emit('open-modal', roomId, date.subtract(1, 'days').format('YYYY/MM/DD'), date.format('YYYY/MM/DD'))
    },
    openReservation(obfuscatedId) {
      if (!obfuscatedId) return

      window.location.href = `/reservations/${obfuscatedId}`
    },
  },
}
</script>

<style lang="scss" scoped>
.home-calendar {
  display: flex;
  color: #67635e;

  &__rooms {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    gap: 5px 1px;
    width: 50px;
    padding-top: 75px;
    border-right: 1px solid #f2f2f2;
    @media (width >= 768px) {
      width: 80px;
      padding-top: 95px;
    }
  }

  &__room-name {
    display: flex;
    align-items: center;
    height: 30px;
    margin-right: 5px;
    @media (width >= 768px) {
      height: 50px;
    }

    & a {
      display: -webkit-box;
      overflow: hidden;
      font-size: 14px;
      line-height: 1.3rem;
      text-decoration: underline;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      @media (width <= 767px) {
        font-size: 10px;
      }
    }
  }

  &__body {
    display: grid;
    grid-template-rows: 75px auto;
    width: 100%;
    overflow-x: auto;
    @media (width >= 768px) {
      grid-template-rows: 95px auto;
      padding-bottom: 10px;

      &::-webkit-scrollbar {
        height: 10px;
        background: #fff;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #e2e2e2;
        border-radius: 4px;
      }
    }
  }

  &__timelines {
    display: grid;
    grid-template-columns: 20px repeat(auto-fill, 40px);
    grid-auto-rows: 30px;
    gap: 5px 1px;
    @media (width >= 768px) {
      grid-template-columns: 30px repeat(auto-fill, 60px);
      grid-auto-rows: 50px;
    }
  }

  &__timeline {
    display: grid;
    grid-template-columns: 20px repeat(auto-fill, 40px);
    grid-auto-rows: 30px;
    gap: 1px;
    @media (width >= 768px) {
      grid-template-columns: 30px repeat(auto-fill, 60px);
      grid-auto-rows: 50px;
    }
  }

  &__booking {
    &--vacancy {
      cursor: pointer;
      background: #fbf6ee;
    }

    &--holiday {
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      background: #e5e5e5;
      outline: 1px solid #fff;
      outline-offset: 0;
      @media (width >= 768px) {
        font-size: 12px;
      }
    }

    &--reserved {
      display: grid;
      grid-template-columns: repeat(auto-fill, 40px);
      grid-auto-rows: 30px;
      background-color: #d5c9c4;
      background-image: repeating-linear-gradient(-45deg, #eee9e6, #eee9e6 2px, transparent 0, transparent 6px);
      @media (width >= 768px) {
        grid-template-columns: repeat(auto-fill, 60px);
        grid-auto-rows: 50px;
      }
    }

    &--ownreserved {
      display: grid;
      grid-template-columns: repeat(auto-fill, 40px);
      grid-auto-rows: 30px;
      cursor: pointer;
      background: #8ba16b;
      @media (width >= 768px) {
        grid-template-columns: repeat(auto-fill, 60px);
        grid-auto-rows: 50px;
      }
    }

    &--unavailable {
      display: flex;
      align-items: center;
      font-size: 10px;
      background: #e5e5e5;
      @media (width >= 768px) {
        font-size: 12px;
      }

      & span {
        position: sticky;
        left: 1em;
        opacity: 0.6;
      }
    }

    &--blocked {
      display: grid;
      grid-template-columns: repeat(auto-fill, 40px);
      grid-auto-rows: 30px;
      font-size: 10px;
      background: #e5e5e5;
      @media (width >= 768px) {
        grid-template-columns: repeat(auto-fill, 60px);
        grid-auto-rows: 50px;
        font-size: 12px;
      }

      & span {
        align-content: center;
        justify-self: center;
      }
    }

    &--paused {
      z-index: 1;
      display: flex;
      align-items: center;
      padding-left: 1em;
      font-size: 10px;
      background: #e5e5e5;
      outline: 1px solid #fff;
      outline-offset: 0;
      @media (width >= 768px) {
        font-size: 12px;
      }
    }
  }
}
</style>
