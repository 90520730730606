<template>
  <div>
    <div @click.prevent="openModal">
      <slot name="button"></slot>
    </div>
    <Teleport to="body">
      <div class="info-modal">
        <modal
          :name="modalName"
          height="auto"
          classes="modal-wrapper"
          scrollable
          style="display: flex; align-items: center; justify-content: center; height: 100%"
        >
          <button type="button" class="modal-closebtn" @click.prevent="$modal.hide(modalName)"></button>
          <div class="home-info-modal">
            <slot name="modal"></slot>
          </div>
        </modal>
      </div>
    </Teleport>
  </div>
</template>

<script>
import Teleport from 'vue2-teleport'

export default {
  components: {
    Teleport,
  },
  props: {
    modalName: {
      type: String,
      required: true,
    },
  },
  methods: {
    openModal() {
      this.$modal.show(this.modalName)
    },
  },
}
</script>

<style lang="scss">
.info-modal {
  .vm--container {
    z-index: 1030 !important;
  }

  & .vm--modal {
    top: initial !important;
    left: initial !important;
    width: initial !important;
    height: initial !important;
    margin-top: 20px !important;
    background-color: initial !important;
    border-radius: 0 !important;
    box-shadow: initial !important;

    @media (width <= 767px) {
      width: 100vw !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  & .vm--block-scroll header {
    z-index: initial !important;
  }

  & .vm--block-scroll .sticky {
    z-index: initial !important;
  }
}

.home-info-modal {
  position: relative;
  overflow-y: scroll;
  -ms-overflow-style: none;

  @media (width >= 768px) {
    max-height: 90vh;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  @media (width <= 767px) {
    display: block;
    height: 100vh;
  }
}
</style>
