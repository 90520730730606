<template>
  <div :class="className" :style="'grid-row: ' + row + '; grid-column: ' + column + ';'">
    {{ stock }}
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      className: 'ok',
      stock: '',
      row: 0,
      column: 0,
    }
  },
  mounted: function () {
    this.setClassName()
    this.setRow()
    this.setColumn()
    this.setStock()
  },
  methods: {
    setClassName() {
      if (this.count === 0) {
        this.className = 'ng'
      }
    },
    setRow() {
      this.row = (this.index % 3) + 1
    },
    setColumn() {
      Math.floor(this.index / 3) + 1
    },
    setStock() {
      if (this.count >= 1) {
        this.stock = this.count
      }
    },
  },
}
</script>
