<template>
  <button class="homedetail-header-favo" :disabled="isLoading" @click.prevent="toggleFavorite">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 24 24"
      :fill="fillColor"
      :stroke="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"
      ></path>
    </svg>
  </button>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    defaultIsFavored: {
      type: Boolean,
      required: true,
    },
    apiUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isFavored: false,
      isLoading: false,
    }
  },
  computed: {
    fillColor() {
      return this.isFavored ? '#cb3c3b' : 'none'
    },
    strokeColor() {
      return this.isFavored ? 'none' : '#67635e'
    },
  },
  mounted() {
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }
    this.isFavored = this.defaultIsFavored
  },
  methods: {
    async toggleFavorite() {
      this.isLoading = true
      if (this.isFavored) {
        await axios.delete(this.apiUrl).then(() => {
          this.isFavored = false
          this.isLoading = false
        })
      } else {
        await axios.post(this.apiUrl).then(() => {
          this.isFavored = true
          this.isLoading = false
        })
      }
    },
  },
}
</script>
