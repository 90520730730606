<template>
  <div>
    <section v-if="!showSp" class="homedetail-images-pc">
      <div class="homedetail-images-pc-inner">
        <button
          v-for="image in featuredImages"
          :key="image.id"
          class="homedetail-images-pc-item"
          @click.prevent="openGallery(image.id)"
        >
          <img :src="image.src" :alt="image.alt" />
        </button>
      </div>
      <button class="homedetail-images-pc-btn" @click.prevent="openGallery(null)">すべての写真を表示</button>
    </section>
    <section v-else class="homedetail-images-sp">
      <slick
        ref="slick"
        :options="slickOptions"
        class="homedetail-images-sp-inner"
        @beforeChange="handleBeforeChange"
        @afterChange="handleAfterChange"
        @init="handleInit"
      >
        <div v-for="image in images" :key="image.id" class="homedetail-images-sp-item" @click.prevent="openGallery(image.id)">
          <img :src="image.src" :alt="image.alt" />
        </div>
      </slick>
      <div class="homedetail-images-sp-counter">{{ currentSlide }} / {{ images.length }}</div>
    </section>
    <Teleport to="body">
      <div class="home-image-modal">
        <modal name="home-image-gallery" height="auto" scrollable>
          <home-image-gallery-modal
            :images="images"
            :default-image-id="defaultImageId"
            @close-modal="$modal.hide('home-image-gallery')"
          />
        </modal>
      </div>
    </Teleport>
  </div>
</template>

<script>
import Slick from 'vue-slick'
import HomeImageGalleryModal from './HomeImageGalleryModal.vue'
import Teleport from 'vue2-teleport'

export default {
  components: { HomeImageGalleryModal, Slick, Teleport },
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      slickOptions: {
        arrows: false,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 1,
      },
      windowWidth: window.innerWidth,
      currentSlide: 0,
      modalName: 'image-gallery',
      defaultImageId: null,
      isSliding: false,
    }
  },
  computed: {
    featuredImages() {
      return this.images.slice(0, 5)
    },
    showSp() {
      return this.windowWidth < 768
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    openGallery(defaultImageId) {
      if (!this.isSliding) {
        this.defaultImageId = defaultImageId
        this.$modal.show('home-image-gallery')
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    handleInit(event, slick) {
      this.currentSlide = slick.currentSlide + 1
    },
    handleBeforeChange(event, slick, currentSlide, nextSlide) {
      this.currentSlide = nextSlide + 1
      this.isSliding = true
    },
    handleAfterChange() {
      this.isSliding = false
    },
  },
}
</script>

<style lang="scss">
.home-image-modal {
  .vm--modal {
    top: initial !important;
    left: initial !important;
    width: initial !important;
    height: initial !important;
    margin-bottom: 0 !important;
    background-color: initial !important;
    box-shadow: initial !important;
  }

  .vm--container {
    z-index: 1030 !important;
  }
}
</style>
