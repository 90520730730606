<template>
  <div>
    <button class="report-btn" @click.prevent="showModal">
      <svg class="report-btn-icon" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_100_9)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.99997 1.43316C4.3732 1.43316 1.43313 4.37323 1.43313 8C1.43313 11.6268 4.3732 14.5668 7.99997 14.5668C11.6267 14.5668 14.5668 11.6268 14.5668 8C14.5668 4.37323 11.6267 1.43316 7.99997 1.43316ZM0.0213623 8C0.0213623 3.59354 3.59351 0.0213928 7.99997 0.0213928C12.4064 0.0213928 15.9786 3.59354 15.9786 8C15.9786 12.4065 12.4064 15.9786 7.99997 15.9786C3.59351 15.9786 0.0213623 12.4065 0.0213623 8Z"
            fill="#CB3C3B"
          />
          <path d="M8 5.09091V8" stroke="#CB3C3B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M8 10.9091H8.00864" stroke="#CB3C3B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_100_9">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
      報告する
    </button>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    reportId: {
      type: [Number, String],
      required: true,
    },
    reportType: {
      type: String,
      required: true,
    },
    reportApiUrl: {
      type: String,
      required: true,
    },
  },
  methods: {
    showModal() {
      window.GlobalVueInstance.showReportModalWithInfo({
        reportType: this.reportType,
        reportId: this.reportId,
        reportApiUrl: this.reportApiUrl,
      })
    },
  },
}
</script>
