<template>
  <div class="reservation-contents">
    <h3>部屋</h3>
    <room-info :room-data="mainRoom" :is-legacy-plan="isLegacyPlan"></room-info>

    <div v-if="isAccompaniable">
      <h4 class="bold mt-4">この部屋の利用者</h4>
      <accompany-user-select
        id="reservation"
        form-name="reservation[accompany_user_ids]"
        :max-users="mainRoom.capacity"
        :accompany-users="accompanyUsers"
        :acceptable-sex-type="mainRoom.acceptable_sex"
        :selected-user-ids="selectedUserIds"
        :is-dormitory="mainRoom.is_dormitory"
        :already-selected-user-ids="selectedUserIdsAsSecond"
        include-principal
        @update-selected="updateAccompanies"
      ></accompany-user-select>
    </div>

    <div v-if="multiReservable">
      <div v-if="childReservation">
        <h3 class="reservation-ttl-secondroom">2部屋目</h3>
        <p>同伴者用に、同日程でもう1部屋予約できます。チケットは2部屋分の枚数を消化します。</p>
        <h4 class="bold mt-4">2部屋目の利用者</h4>
        <accompany-user-select
          id="childReservation"
          form-name="child_reservation[accompany_user_ids]"
          :max-users="secondRoomCapacity"
          :accompany-users="accompanyUsers"
          :acceptable-sex-type="secondRoomAcceptableSex"
          :selected-user-ids="selectedUserIdsAsSecond"
          :is-dormitory="secondRoomIsDormitory"
          :already-selected-user-ids="selectedUserIds"
          @update-selected="updateSecondAccompanies"
        ></accompany-user-select>
        <div class="flex mt-5 mb-3">
          <h4 class="bold">部屋を選択</h4>
          <div v-if="secondRoom">
            <button class="underline text-sub" @click.prevent="openRoomModal">部屋を変更</button>
          </div>
        </div>
        <div v-if="secondRoom">
          <room-info :room-data="secondRoom"></room-info>
          <input type="hidden" name="child_reservation[room_id]" :value="secondRoom.id" />
        </div>
        <div v-else>
          <button class="btn btn-single btn-white" @click.prevent="openRoomModal">部屋を選択</button>
        </div>
        <div class="text-center mt-5 pt-2">
          <button class="bold text-alert" @click.prevent="removeChildReservation">2部屋目を削除</button>
        </div>
      </div>

      <div v-else class="mt-5">
        <button class="btn btn-single btn-white" @click.prevent="addChildReservation">2部屋目を追加</button>
      </div>
    </div>

    <modal
      name="room-modal"
      height="auto"
      classes="room-modal"
      scrollable
      :style="`z-index: 1050;display: flex; align-items: ${
        availableRooms.length > 0 ? 'flex-start' : 'center'
      };justify-content: center;height: 100%;`"
    >
      <div class="modal-box d-flex justify-content-center align-items-center">
        <div v-if="availableRooms.length > 0" class="modal-box-inner">
          <div class="modal-box-header">
            <p class="bold">2部屋目を選択</p>
          </div>
          <div class="modal-box-body room-modal">
            <room-modal-item
              v-for="room in availableRooms"
              :key="room.id"
              :room-data="room"
              :remaining-ticket-amount="remainingTicketAmount"
              @select="selectedSecondRoom"
            ></room-modal-item>
          </div>
          <div class="modal-box-close">
            <button @click.prevent="$modal.hide('room-modal')">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#67635e"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
        </div>
        <div v-else class="modal-box-inner">
          <div class="modal-box-body">
            <p class="text-lg bold mb-2">選択できる部屋がありません</p>
            <p>人数や性別などの条件から、同伴者の方がご利用できる部屋がありません。</p>
            <div class="modal-box-close">
              <button @click.prevent="$modal.hide('room-modal')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#67635e"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="add-room-modal"
      height="auto"
      classes="room-modal"
      style="z-index: 1050; display: flex; align-items: center; justify-content: center; height: 100%"
    >
      <div class="modal-box d-flex justify-content-center align-items-center">
        <div class="modal-box-inner">
          <div class="modal-box-body">
            <p>
              「2部屋目を追加」は、同伴者用に同日程でもう1部屋予約できる機能です。
              <br />
              同伴者登録を済ませてからご利用ください。
              <br />
              登録申請は
              <a
                href="https://address-membersupport.zendesk.com/hc/ja/articles/15085997814297"
                class="btn-text2 text-normal underline"
                target="_blank"
                rel="noopener"
              >
                こちら
              </a>
              からお願いします。※申請から登録まで2営業日ほどかかります。
            </p>
            <div class="modal-box-close">
              <button @click.prevent="$modal.hide('add-room-modal')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#67635e"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import RoomInfo from './RoomInfo.vue'
import AccompanyUserSelect from './reservations/AccompanyUserSelect.vue'
import RoomModalItem from './reservations/RoomModalItem.vue'
import axios from 'axios'

export default {
  components: { RoomModalItem, AccompanyUserSelect, RoomInfo },
  props: {
    mainRoom: {
      type: Object,
      required: true,
    },
    multiReservable: {
      type: Boolean,
      default: false,
    },
    accompanyUsers: {
      type: Array,
      default: () => [],
    },
    defaultUserIds: {
      type: Array,
      default: () => [],
    },
    defaultSecondRoom: {
      type: Object,
      default: () => {},
    },
    defaultUserIdsAsSecond: {
      type: Array,
      default: () => [],
    },
    remainingTicketAmount: {
      type: Number,
      default: null,
    },
    isAccompaniable: {
      type: Boolean,
      default: true,
    },
    roomSearchApiPath: {
      type: String,
      required: true,
    },
    checkInDate: {
      type: String,
      required: true,
    },
    checkOutDate: {
      type: String,
      required: true,
    },
    isLegacyPlan: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      childReservation: false,
      secondRoom: null,
      availableRooms: [],
      selectedUserIds: [],
      selectedUserIdsAsSecond: [],
    }
  },
  computed: {
    secondRoomCapacity() {
      return this.secondRoom?.capacity || 10
    },
    secondRoomAcceptableSex() {
      return this.secondRoom?.acceptable_sex || 'all'
    },
    secondRoomIsDormitory() {
      return this.secondRoom?.is_dormitory || false
    },
    accompaniesInSecond() {
      return this.accompanyUsers.filter((user) => this.selectedUserIdsAsSecond.includes(user.id))
    },
    countableAccompaniesInSecond() {
      return this.accompaniesInSecond.filter((user) => user.age_kind !== '幼児')
    },
    acceptableCapacity() {
      return this.countableAccompaniesInSecond.length
    },
    acceptableSexKind() {
      const uniquesKinds = [...new Set(this.countableAccompaniesInSecond.map((user) => user.sex))]
      if (uniquesKinds.length > 1) {
        return 'all'
      } else {
        return uniquesKinds[0]
      }
    },
  },
  watch: {
    defaultSecondRoom: {
      handler(newRoom) {
        this.secondRoom = newRoom

        if (newRoom) {
          this.childReservation = true
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.defaultUserIds.length > 0) {
      this.selectedUserIds = this.defaultUserIds
    }
    if (this.defaultUserIdsAsSecond && this.defaultUserIdsAsSecond.length > 0) {
      this.selectedUserIdsAsSecond = this.defaultUserIdsAsSecond
    }
  },
  methods: {
    addChildReservation() {
      if (this.accompanyUsers.length > 0) {
        this.childReservation = true
      } else {
        this.$modal.show('add-room-modal')
      }
    },
    removeChildReservation() {
      this.childReservation = false
      this.secondRoom = null
      this.selectedUserIdsAsSecond = []
    },
    async openRoomModal() {
      await this.fetchAvailableRooms()
      this.$modal.show('room-modal')
    },
    async fetchAvailableRooms() {
      await axios.get(this.roomSearchApiPath, { params: this.searchParams() }).then((response) => {
        this.availableRooms = response.data.rooms
      })
    },
    searchParams() {
      const params = {}
      params.exclude = this.mainRoom.id
      params.check_in_date = this.checkInDate
      params.check_out_date = this.checkOutDate

      if (this.accompaniesInSecond.length > 0) {
        params.capacity = this.acceptableCapacity
        params.sex = this.acceptableSexKind
      }

      return params
    },
    updateAccompanies(ids) {
      this.selectedUserIds = ids
    },
    updateSecondAccompanies(ids) {
      this.selectedUserIdsAsSecond = ids
    },
    selectedSecondRoom(room) {
      this.$modal.hide('room-modal')
      this.secondRoom = room
    },
  },
}
</script>

<style lang="scss">
.vm--modal.room-modal {
  top: initial !important;
  left: initial !important;
  width: initial !important;
  height: initial !important;
}
</style>
